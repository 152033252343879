<template>
  <div
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      h-screen
      overflow-y-scroll
      pb-16
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Buy Data
    </h1>
    <div class="my-3 text-sm">Easy and fast data purchase.</div>
    <div
      class="
        switch-container
        rounded-lg
        flex
        items-center
        p-1
        my-8
        font-semibold
        text-sm text-legend-gray
        dark:text-border-color
        text-center
      "
    >
      <div
        class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
        :class="{ 'bg-white text-primary-color': isSelf }"
        @click="toggleSelf"
      >
        For myself
      </div>
      <div
        class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
        :class="{ 'bg-white text-primary-color': isOthers }"
        @click="toggleOthers"
      >
        For others
      </div>
    </div>
    <div class="mt-6 mb-2 font-semibold">Select provider</div>
    <div class="mb-6">
      <div class="flex">
        <div
          v-for="(provider, index) in this.providers"
          :key="index"
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="getProviderDetails(provider)"
        >
          <img :src="getImgUrl(provider.logo)" :alt="'buy ' + provider.title" />
          <img
            v-if="providerName === provider.title"
            src="@/assets/images/check-mark-circle.svg"
            :alt="'buy ' + provider.title"
            class="absolute left-2 top-2"
          />
        </div>
      </div>
      <ValidationMessage v-if="loading && !providerName">
        <span slot="message">You must select a provider.</span>
      </ValidationMessage>
    </div>

    <div class="my-6 relative">
      <div class="w-full relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Select Data Plan</label
        >
        <div
          class="
            plan-options
            flex
            items-center
            w-full
            border border-border-color
            rounded-lg
            py-3
            px-1
          "
        >
          <input
            v-model="selectedPackage"
            type="text"
            readonly
            class="
              plan-options
              w-full
              px-4
              bg-transparent
              focus:outline-none
              capitalize
            "
            @click="getDataPackages"
          />
        </div>
      </div>
      <img
        src="@/assets/images/down-chevron.svg"
        alt="select provider"
        class="w-3 absolute right-4 top-11 plan-options"
      />
      <ul
        v-click-outside="hideDropdown"
        v-if="packageOptions"
        class="
          absolute
          top-full
          w-full
          max-h-40
          overflow-y-scroll
          overscroll-y-contain
          bg-white
          dark:text-legend-gray
          border-gray-200
          shadow-md
        "
      >
        <li v-if="loading && !packages" class="font-medium text-center py-2">
          Loading...
        </li>
        <li
          v-for="(pack, index) in packages"
          :key="index"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
          @click="selectProvider(pack)"
        >
          {{ pack.name }}
        </li>
      </ul>
    </div>

    <div v-if="selectedPackage" class="mb-6">
      <div
        class="
          font-semibold
          text-sm
          mb-1
          capitalize
          text-legend-gray
          dark:text-border-color
        "
      >
        amount
      </div>
      <input
        v-model="amount"
        type="text"
        readonly
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
        "
      />
    </div>
    <div v-if="selectedPackage" class="mb-6">
      <div class="text-legend-gray dark:text-border-color capitalize w-full">
        <label class="font-semibold text-sm mb-1">phone number</label>
        <input
          v-model="phoneNumber"
          type="text"
          placeHolder="e.g 8030000000"
          class="
            phone-number
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
      <ValidationMessage v-if="loading && !$v.phoneNumber.required">
        <span slot="message">Phone number is required.</span>
      </ValidationMessage>
    </div>
    <div v-if="selectedPackage" class="mb-6">
      <div class="text-legend-gray dark:text-border-color capitalize w-full">
        <label class="font-semibold text-sm mb-1">transaction pin</label>
        <input
          v-model="pin"
          type="password"
          placeHolder="Enter your transaction pin"
          class="
            phone-number
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
      <ValidationMessage v-if="loading && !$v.pin.required">
        <span slot="message">Transaction pin is required.</span>
      </ValidationMessage>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>
    <custom-button :loading="submitting" @sendAction="buyData">
      <span slot="button-text">Buy</span>
    </custom-button>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'BuyData',
  components: {
    XIcon,
  },
  mounted() {
    this.switchTheme()
    if (this.isSelf) {
      this.phoneNumber = this.user.phone

      const node = document.querySelector('.phone-number')
      node.setAttribute('readonly', true)
      node.classList.add('cursor-not-allowed')
    }
  },
  data() {
    return {
      isSelf: true,
      isOthers: false,
      phoneNumber: null,
      amount: null,
      pin: null,
      variation_code: null,
      notification: null,
      loading: false,
      submitting: false,
      packages: null,
      selectedPackage: null,
      providerName: null,
      packageOptions: false,
      providers: [
        {
          title: 'mtn-data',
          logo: 'mtn-logo.svg',
        },
        {
          title: 'airtel-data',
          logo: 'airtel-logo.svg',
        },
        {
          title: 'glo-data',
          logo: 'globacom-logo.svg',
        },
        {
          title: 'etisalat-data',
          logo: '9mobile-logo.svg',
        },
      ],
    }
  },
  validations() {
    return {
      providerName: { required },
      phoneNumber: { required },
      amount: { required },
      pin: { required },
    }
  },
  computed: {
    ...mapGetters(['user', 'mode']),
  },
  watch: {
    mode() {
      this.switchTheme()
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('plan-options')) {
        this.packageOptions = false
      }
    },
    switchTheme() {
      const node = document.querySelector('.switch-container')
      if (this.mode === 'dark') {
        node.classList.add('dark-mode-bg')
        node.classList.remove('light-mode-bg')
      } else {
        node.classList.add('light-mode-bg')
        node.classList.remove('dark-mode-bg')
      }
    },
    toggleSelf() {
      this.isSelf = true
      this.isOthers = false
      this.phoneNumber = this.user.phone
      const node = document.querySelector('.phone-number')
      node.setAttribute('readonly', true)
      node.classList.add('cursor-not-allowed')
    },
    toggleOthers() {
      this.isSelf = false
      this.isOthers = true
      this.phoneNumber = null
      const node = document.querySelector('.phone-number')
      node.removeAttribute('readonly')
      node.classList.remove('cursor-not-allowed')
    },
    setValue(value) {
      this[value.field] = value.value
    },
    getProviderDetails(provider) {
      this.packages = null
      this.providerName = provider.title
      this.selectedPackage = null
    },
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
    selectProvider(provider) {
      this.selectedPackage = provider.name
      this.packageOptions = false
      this.amount = provider.variation_amount
      this.variation_code = provider.variation_code
    },
    async getDataPackages() {
      this.loading = true

      if (!this.providerName) {
        return
      }

      this.selectedPackage = null
      this.amount = null
      this.packageOptions = true
      try {
        const { status, data } = await this.axios.post(
          'account/utility/data/subscriptions',
          {
            provider: this.providerName,
          }
        )

        if (status === 200) {
          this.packages = data.data.varations
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
      }
    },

    async buyData() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post(
          'account/utility/data/subscribe',
          {
            amount: Number(this.amount),
            variation_code: this.variation_code,
            phone_number: this.phoneNumber,
            provider: this.providerName,
            pin: this.pin,
          }
        )

        if (status === 200) {
          this.$emit('utility-purchase', {
            type: 'success',
            message: data.message,
          })

          this.submitting = false
          this.loading = false
        }
      } catch (error) {
        this.submitting = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>

<style scoped>
.switch-container {
  width: 20rem;
}

.light-mode-bg {
  background-color: rgba(253, 79, 0, 0.1);
}

.dark-mode-bg {
  background-color: #1c1c1c;
}

@media only screen and (max-width: 560px) {
  .switch-container {
    width: 100%;
  }

  .switch-container > div {
    font-size: 0.7rem;
  }
}
</style>
