<template>
  <div
    class="
      font-semibold
      grid grid-cols-2
      xl:gap-24
      lg:gap-6
      md:gap-6
      gap-6
      mt-12
    "
  >
    <button
      class="
        border border-primary-color
        rounded-lg
        text-primary-color text-lg
        py-3
      "
      @click="$emit('leftAction')"
    >
      <slot name="left-button" />
    </button>
    <button
      class="
        text-white
        font-semibold
        bg-primary-color
        rounded-lg
        border border-primary-color
        py-3
      "
      :class="{ 'inactive-state': loading }"
      @click="$emit('rightAction')"
    >
      <slot name="right-button" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'TwinButton',
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>
