<template>
  <div class="px-8 h-screen">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-8 -ml-4 -mt-4 text-primary-color cursor-pointer"
      @click="$emit('close-cart')"
    ></x-icon>
    <div class="flex justify-between items-center mb-8">
      <h1 class="font-bold text-xl">My Cart</h1>
      <div class="text-legend-gray dark:text-border-color text-sm">
        {{ cart.length }} {{ cart.length > 1 ? 'items' : 'item' }}
      </div>
    </div>
    <div class="h-3/5 overflow-y-scroll overscroll-contain">
      <div
        v-for="(item, index) in cart"
        :key="index"
        class="
          bg-white
          dark:bg-dark-mode-primary
          rounded
          flex
          p-3.5
          relative
          mb-4
        "
      >
        <div class="w-28 h-28 rounded bg-gray-300 mr-3 flex-shrink-0">
          <img
            v-if="
              item.product && item.product.images && item.product.images.length
            "
            :src="`${url}/${item.product.images[0].path}/${item.product.images[0].name}.${item.product.images[0].mimetype}`"
            :alt="item.product.name"
            class="w-full h-full object-cover"
          />
        </div>
        <div
          class="
            w-full
            text-legend-gray
            dark:text-border-color
            justify-between
            flex flex-col
          "
        >
          <h1 class="font-bold pr-6">{{ item.product.name }}</h1>
          <div class="text-sm mt-2 pr-6">{{ item.product.description }}</div>
          <div class="flex flex-row items-center justify-between">
            <div class="font-bold text-xl">
              ₦{{ item.product.price | moneyFormat }}
            </div>
            <div class="flex items-center">
              <span
                class="
                  bg-main-border
                  dark:bg-dark-mode-secondary dark:text-border-color
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                @click="decrementQuantity(item.id)"
                >-</span
              >
              <span
                class="
                  border border-main-border
                  dark:border
                  dark:border-dark-mode-secondary
                  dark:bg-dark-mode-secondary
                  dark:text-border-color
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  font-bold
                  mx-2
                "
                >{{ item.quantity }}</span
              >
              <span
                class="
                  bg-main-border
                  dark:bg-dark-mode-secondary dark:text-border-color
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                @click="incrementQuantity(item.id)"
                >+</span
              >
            </div>
          </div>
        </div>
        <div
          class="
            absolute
            top-3.5
            right-3.5
            border border-primary-red
            rounded
            cursor-pointer
            p-1
          "
          @click="deleteCartItem(item.id)"
        >
          <img
            src="@/assets/images/trash-icon.svg"
            alt="delete item from cart"
          />
        </div>
      </div>
    </div>

    <div class="absolute bottom-3 left-0 w-full px-8 pb-8">
      <custom-button @sendAction="openCheckout">
        <span slot="button-text">Checkout</span>
      </custom-button>
    </div>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import errorModule from '@/util/error.handle'

export default {
  name: 'CartSystem',
  props: {
    cart: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    XIcon,
  },
  data() {
    return {
      openCart: false,
      rating: 4.5,
      notification: null,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  methods: {
    decrementQuantity(itemId) {
      this.$store.commit('DECREMENT_COUNT', itemId)
      this.updateCount(itemId)
    },
    incrementQuantity(itemId) {
      this.$store.commit('INCREMENT_COUNT', itemId)
      this.updateCount(itemId)
    },
    async updateCount(itemId) {
      try {
        const item = this.cart.find((tick) => tick.id === itemId)

        const { status } = await this.axios.patch('account/cart', {
          cart_items: [
            {
              item_id: itemId,
              quantity: item.quantity,
            },
          ],
        })

        if (status !== 200) {
          this.notification = {
            type: 'info',
            message: 'cart failed to update.',
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async deleteCartItem(itemId) {
      try {
        const { status } = await this.axios.delete(`account/cart/${itemId}`)

        if (status === 200) {
          this.$store.commit('REMOVE_ITEM', itemId)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    openCheckout() {
      this.$emit('close-cart')
      if (this.$route.name !== 'CartCheckout') {
        this.$router.push({
          name: 'CartCheckout',
        })
      }
    },
  },
}
</script>
