<template>
  <div
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      pb-16
      h-screen
      overflow-y-scroll
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Buy TV Subscription
    </h1>
    <!--    <div class="my-3 text-sm">Select Service Provider.</div>-->

    <div class="mt-6 mb-2 font-semibold">Select Service Provider</div>
    <div class="mb-6">
      <div class="flex">
        <div
          v-for="(provider, index) in this.providers"
          :key="index"
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="getProviderDetails(provider)"
        >
          <img :src="getImgUrl(provider.logo)" :alt="'buy ' + provider.title" />
          <img
            v-if="providerName === provider.title"
            src="@/assets/images/check-mark-circle.svg"
            :alt="'buy ' + provider.title"
            class="absolute left-2 top-2"
          />
        </div>
      </div>
      <ValidationMessage v-if="loading && !$v.providerName.required">
        <span slot="message">You must select a provider.</span>
      </ValidationMessage>
    </div>

    <div class="my-6 relative">
      <div class="w-full relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Select Provider</label
        >
        <div
          class="
            plan-options
            flex
            items-center
            w-full
            relative
            border border-border-color
            rounded-lg
            py-3
            px-1
          "
        >
          <input
            v-model="selectedPlan"
            type="text"
            readonly
            class="
              w-full
              px-4
              bg-transparent
              focus:outline-none
              capitalize
              plan-options
            "
            @click="packageOptions = !packageOptions"
          />
          <chevron-down-icon
            size="1.3x"
            class="custom-class absolute top-4 right-4 plan-options"
          ></chevron-down-icon>
        </div>
        <ValidationMessage v-if="loading && !selectedPackage">
          <span slot="message">Select a package</span>
        </ValidationMessage>
      </div>
      <ul
        v-click-outside="hideDropdown"
        v-if="packageOptions"
        class="
          absolute
          top-full
          w-full
          h-40
          overflow-y-scroll
          overscroll-y-contain
          bg-white
          dark:bg-dark-mode-primary dark:text-border-color
          border-gray-200
          shadow-md
          z-index-1
          text-sm
          z-50
        "
      >
        <li v-if="packageOptions && !packages" class="font-semibold py-2 px-4">
          Loading packages...
        </li>
        <li
          v-for="(pack, index) in packages"
          :key="index"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
          @click="selectProvider(pack)"
        >
          {{ pack.name }}
        </li>
      </ul>
    </div>

    <div class="my-6 relative">
      <div class="font-semibold text-sm mb-1 capitalize">amount</div>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
    </div>

    <div>
      <custom-input
        :inputData="{
          label: 'Phone number',
          fieldName: 'phone_number',
          placeHolder: 'Enter your phone number',
        }"
        @input-value="setValue"
      >
      </custom-input>

      <ValidationMessage
        v-if="loading && !$v.phone_number.required"
        class="-mt-6"
      >
        <span slot="message">Enter your Phone number.</span>
      </ValidationMessage>
    </div>
    <div v-if="!showmax" class="relative">
      <custom-input
        :inputData="{
          label: 'Smart card',
          fieldName: 'smart_card',
          placeHolder: 'Enter smart card number',
        }"
        @input-value="setValue"
      >
      </custom-input>
      <div
        v-if="verifyingSmartCard"
        class="text-secondary-green font-semibold absolute top-20"
      >
        Verifying smart card...
      </div>
      <ValidationMessage
        v-if="loading && !$v.smart_card.required"
        class="-mt-6"
      >
        <span slot="message">Enter your smart card number.</span>
      </ValidationMessage>
    </div>
    <div>
      <custom-input
        :inputData="{
          label: 'transaction pin',
          fieldName: 'pin',
          placeHolder: 'Enter your pin',
        }"
        attributeType="password"
        @input-value="setValue"
      >
      </custom-input>

      <ValidationMessage v-if="loading && !$v.pin.required" class="-mt-6">
        <span slot="message">Transaction pin is required.</span>
      </ValidationMessage>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>

    <div class="my-6 relative" v-if="verified && !showmax">
      <label
        class="
          text-legend-gray
          dark:text-border-color
          font-semibold
          block
          leading-relaxed
          capitalize
        "
        >Customer name</label
      ><input
        v-model="customer_name"
        type="text"
        readonly
        class="
          text-seconadry-border
          border border-border-color
          rounded-lg
          w-full
          p-3
          focus:outline-none
          bg-transparent
        "
      />
    </div>

    <custom-button
      v-if="!verified && !showmax"
      :loading="submitting"
      @sendAction="verifySmartCard"
    >
      <span slot="button-text">Verify</span>
    </custom-button>

    <custom-button
      v-if="verified || showmax"
      :loading="submitting"
      @sendAction="buyTvSubscription"
    >
      <span slot="button-text">Buy</span>
    </custom-button>
  </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'
export default {
  name: 'BuyTvSubscription',
  components: {
    XIcon,
    ChevronDownIcon,
    CustomInput,
  },
  data() {
    return {
      notification: null,
      loading: false,
      submitting: false,
      selectedPackage: null,
      selectedPlan: null,
      customer_name: '',
      packages: null,
      providerName: 'dstv',
      packageOptions: false,
      smart_card: null,
      amount: null,
      pin: null,
      verified: false,
      showmax: false,
      verifyingSmartCard: false,
      phone_number: '',
      providers: [
        {
          title: 'dstv',
          logo: 'dstv.svg',
        },
        {
          title: 'gotv',
          logo: 'gotv.svg',
        },
        {
          title: 'startimes',
          logo: 'startimes.svg',
        },
        {
          title: 'showmax',
          logo: 'showmax.svg',
        },
      ],
    }
  },
  validations() {
    if (this.showmax) {
      return {
        providerName: { required },
        amount: { required },
        phone_number: { required },
        pin: { required },
      }
    } else {
      return {
        providerName: { required },
        amount: { required },
        phone_number: { required },
        smart_card: { required },
        pin: { required },
      }
    }
  },
  mounted() {
    this.getPackages()
  },
  computed: {
    ...mapGetters(['user']),
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('plan-options')) {
        this.packageOptions = false
      }
    },
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
    setValue(value) {
      this[value.field] = value.value
    },
    getProviderDetails(provider) {
      this.packages = null
      this.providerName = provider.title
      this.getPackages()
      this.showmax = this.providerName === 'showmax'
    },
    async getPackages() {
      this.selectedPackage = null
      this.amount = ''
      try {
        const { status, data } = await this.axios.post(
          'account/utility/tv/subscriptions',
          {
            provider: this.providerName,
          }
        )
        if (status === 200) {
          this.packages = data.data.varations
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async verifySmartCard() {
      try {
        if (this.submitting) {
          return
        }
        this.loading = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.submitting = true
        this.verifyingSmartCard = true
        const { status, data } = await this.axios.post(
          'account/utility/tv/verify-card',
          {
            provider: this.providerName,
            card_number: this.smart_card,
          }
        )
        if (status >= 200 && status < 300) {
          this.verified = true
          this.verifyingSmartCard = false
          this.customer_name = data.data.Customer_Name
          this.submitting = false
          this.loading = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.verifyingSmartCard = false
        this.submitting = false
        this.loading = false
      }
    },
    async buyTvSubscription() {
      let request_body
      if (this.providerName === 'showmax') {
        request_body = {
          amount: this.amount,
          variation_code: this.selectedPackage.variation_code,
          phone_number: this.phone_number,
          provider: this.providerName,
          pin: this.pin,
        }
        await this.payTvSubscription(request_body)
      } else {
        if (this.verified === true) {
          request_body = {
            amount: this.amount,
            variation_code: this.selectedPackage.variation_code,
            card_number: this.smart_card,
            phone_number: this.phone_number,
            provider: this.providerName,
            pin: this.pin,
          }
          await this.payTvSubscription(request_body)
        }
      }
    },
    async payTvSubscription(request_body) {
      try {
        if (this.submitting) {
          return
        }
        this.loading = true

        if (this.loading && !this.selectedPackage) {
          return
        }

        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.submitting = true
        const { status, data } = await this.axios.post(
          'account/utility/tv/subscribe',
          request_body
        )
        if (status >= 200 && status < 300) {
          this.packages = null
          this.amount = null
          this.selectedPlan = null
          this.loading = false
          this.submitting = false

          this.notification = {
            type: 'success',
            message: data.message,
          }

          setTimeout(() => this.$emit('closeModal'), 4000)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
        this.loading = false
        this.packages = null
        this.selectedPackage = null
        this.amount = null
      }
    },
    selectProvider(provider) {
      this.selectedPackage = provider
      this.selectedPlan = provider.name
      this.packageOptions = false
      this.amount = Number(provider.variation_amount)
    },
  },
}
</script>
Footer
