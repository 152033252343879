<template>
  <main
    class="
      flex
      bg-primary-background
      dark:bg-dark-mode-main dark:text-border-color
      relative
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <SideNav v-if="!isExempted" />
    <section class="w-full xl:mx-6 lg:mx-0 md:mx-0 mx-0">
      <AppBar
        v-if="!isExempted"
        @open-cart="openCart = true"
        @open-notification="openNotification = true"
      />
      <router-view />
    </section>
    <custom-modal v-if="openCart && cart.length">
      <CartSystem :cart="cart" slot="cart" @close-cart="openCart = false" />
    </custom-modal>
    <custom-modal v-if="openNotification">
      <NotificationSystem
        :notifications="notificationItems"
        slot="cart"
        @close-cart="openNotification = false"
      />
    </custom-modal>
    <main
      v-if="savingTransaction"
      class="
        w-screen
        h-screen
        bg-legend-black
        dark:bg-gray-50 dark:bg-opacity-20
        bg-opacity-70
        fixed
        flex
        items-center
        justify-center
        top-0
        left-0
        z-40
      "
    >
      <section class="px-14 py-6 rounded-md bg-primary-color">
        <LoadingSpinner />
        <p class="text-white font-semibold text-lg my-4">Funding your wallet</p>
        <p class="text-center text-white font-light">Please wait...</p>
      </section>
    </main>
    <custom-modal v-if="fundingWallet">
      <FundWallet slot="cart" @utility-purchase="handleResponse" />
    </custom-modal>
  </main>
</template>

<script>
import CartSystem from '@/components/Cart.vue'
import NotificationSystem from '@/components/Notifications.vue'
import FundWallet from '@/components/FundWallet.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'RootComponenet',
  components: { CartSystem, NotificationSystem, FundWallet },
  computed: {
    ...mapGetters([
      'token',
      'cart',
      'savingTransaction',
      'walletBalance',
      'fundingWallet',
    ]),
    isExempted() {
      return this.exemptedRoutes.includes(this.$route.name)
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
  },
  mounted() {
    if (this.token) {
      this.fetchCartItems()
      this.getProfile()
      this.fetchNotifications()
    }
    this.$store.commit('SET_TRANSACTION_STATUS', false)
  },
  data() {
    return {
      notification: null,
      openCart: false,
      openNotification: false,
      notificationItems: null,
      exemptedRoutes: [
        'Register',
        'Login',
        'Verify',
        'Forgot',
        'CreatePassword',
        'Password',
      ],
    }
  },
  watch: {
    isIdle() {
      if (this.isIdle && !this.exemptedRoutes.includes(this.$route.name)) {
        this.logout()
      }
    },
    token() {
      if (!this.token) {
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 500)
      }
    },
    walletBalance(nw, old) {
      if (nw > old) {
        this.$store.commit('SET_TRANSACTION_STATUS', false)
      }
    },
  },
  methods: {
    handleResponse(data) {
      this.notification = {
        type: data.type,
        message: data.message,
      }
    },
    async fetchCartItems() {
      try {
        const { status, data } = await this.axios.get('/account/cart')

        if (status === 200) {
          this.$store.commit('SET_CART', data.data.cart_items)
        }
      } catch (error) {
        errorModule(error, this.notification, this.$router, this.$store)
      }
    },
    async getProfile() {
      try {
        const { status, data } = await this.axios.get('/account')

        if (status === 200) {
          this.$store.commit('SET_USER', data.data)
        }
      } catch (error) {
        errorModule(error, this.notification, this.$router, this.$store)
      }
    },
    async fetchNotifications() {
      try {
        const { status, data } = await this.axios.get('/account/notifications')

        if (status === 200) {
          this.notificationItems = data.data.notifications
        }
      } catch (error) {
        errorModule(error, this.notification, this.$router, this.$store)
      }
    },
    async logout() {
      try {
        const { status } = await this.axios.get('/account/logout')

        if (status === 200) {
          this.$store.dispatch('logOut')
        }
      } catch (error) {
        errorModule(error, this.notification, this.$router, this.$store)
      }
    },
  },
}
</script>

<style>
#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262324;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
