<template>
  <section class="px-10 h-screen overflow-y-scroll overscroll-contain pb-16">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-6 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl">Transfer Funds</h1>
    <div class="text-legend-gray dark:text-border-color mt-2 mb-8">
      Send funds to your favourite person
    </div>
    <div class="relative mt-6">
      <label
        class="
          text-legend-gray
          dark:text-border-color
          font-semibold
          block
          leading-relaxed
        "
        >Username</label
      ><input
        v-debounce:1000ms="validateUsername"
        v-model="username"
        type="text"
        placeholder="e.g legend"
        class="
          text-seconadry-border
          border border-border-color
          rounded-lg
          w-full
          p-3
          focus:outline-none
          bg-transparent
        "
      />
      <div class="text-secondary-green text-sm py-1">
        <span
          v-if="validatingUser && !responseMessage"
          class="text-secondary-green dark:text-border-color"
          >Checking username...</span
        >
      </div>
      <ValidationMessage
        v-if="loading && !$v.username.required"
        class="absolute top-16"
      >
        <span slot="message">Username is required.</span>
      </ValidationMessage>
    </div>
    <div v-if="responseMessage">
      <div class="mt-6">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Recipient name</label
        ><input
          v-model="full_name"
          type="text"
          readonly
          placeholder="Enter name of recipient"
          class="
            text-seconadry-border
            border border-border-color
            rounded-lg
            w-full
            p-3
            focus:outline-none
            bg-transparent
            cursor-not-allowed
          "
        />
        <ValidationMessage v-if="loading && !$v.full_name.required">
          <span slot="message">Recipient name is required.</span>
        </ValidationMessage>
      </div>
    </div>
    <div v-if="full_name" class="relative mt-6">
      <div class="font-semibold text-sm mb-1 capitalize">amount</div>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model.lazy="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage
        v-if="loading && !$v.amount.required"
        class="absolute left-0 top-16"
      >
        <span slot="message">You must enter an amount to transfer.</span>
      </ValidationMessage>
    </div>
    <div v-if="responseMessage">
      <div class="mt-6">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Transaction Pin</label
        ><input
          v-model="transaction_pin"
          type="password"
          placeholder="****"
          class="
            text-seconadry-border
            border border-border-color
            rounded-lg
            w-full
            p-3
            focus:outline-none
            bg-transparent
          "
        />
        <ValidationMessage v-if="loading && !$v.transaction_pin.required">
          <span slot="message">Your Transaction Pin is required.</span>
        </ValidationMessage>
      </div>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>
    <custom-button :loading="submitting" @sendAction="confirmAction">
      <span slot="button-text">Send</span>
    </custom-button>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'

export default {
  name: 'TransferFunds',
  components: {
    XIcon,
  },
  data() {
    return {
      username: null,
      amount: null,
      full_name: null,
      responseMessage: null,
      transaction_pin: null,
      loading: false,
      submitting: false,
      validatingUser: false,
      notification: null,
    }
  },
  validations() {
    return {
      username: { required },
      amount: { required },
      full_name: { required },
      transaction_pin: { required },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    setValue(value) {
      this[value.field] = value.value
    },
    confirmAction() {
      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.submitting = true

      this.$emit('confirmAction', {
        username: this.username,
        amount: this.amount,
        full_name: this.full_name,
        transaction_pin: this.transaction_pin,
      })
    },
    async validateUsername() {
      try {
        this.validatingUser = true
        const { status, data } = await this.axios.post('/account/username', {
          username: this.username,
        })

        if (status >= 200 && status < 300) {
          this.validatingUser = false
          this.responseMessage = `${data.message} `
          this.full_name = `${data.data.firstname} ${data.data.lastname}`
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        } else if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.username[0],
          }
        }
        this.validatingUser = false
        this.full_name = null
      }
    },
  },
}
</script>
