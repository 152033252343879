<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <div
      class="
        w-80
        bg-primary-color
        text-white
        flex
        items-end
        justify-between
        px-5
        py-6
        mt-5
        mb-16
      "
    >
      <div class="flex items-center">
        <img
          src="@/assets/images/subscription-icon-light.svg"
          alt="go to subscriptions"
          class="w-8"
        />
        <div class="ml-5">
          <div>Wallet Balance</div>
          <div class="font-bold mt-2">₦{{ walletBalance | moneyFormat }}</div>
        </div>
      </div>
    </div>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Renew plan
    </h1>
    <div class="my-3 text-sm text-legend-gray dark:text-border-color mb-8">
      Renew your Legend plan.
    </div>
    <div
      class="
        text-legend-gray text-sm
        grid grid-cols-2
        gap-6
        border border-primary-color
        rounded-md
        bg-primary-color bg-opacity-10
        p-5
        mb-8
      "
    >
      <div>
        <h1 class="text-primary-color font-bold">Subscription Name</h1>
        <p class="dark:text-border-color">{{ currentPlan.bundle }}</p>
      </div>
      <div>
        <h1 class="text-primary-color font-bold">Expiry Date</h1>
        <p class="dark:text-border-color">{{ currentPlan.expiry }}</p>
      </div>
      <div>
        <h1 class="text-primary-color font-bold">Cost of Subscription</h1>
        <p class="dark:text-border-color">
          ₦{{ currentPlan.price | moneyFormat }}
        </p>
      </div>
      <div>
        <h1 class="text-primary-color font-bold">Subscription Balance</h1>
        <p class="dark:text-border-color">
          ₦{{ currentPlan.product_balance | moneyFormat }}
        </p>
      </div>
    </div>

    <div
      class="
        w-full
        flex
        justify-between
        text-legend-gray
        dark:text-border-color
        uppercase
        mb-12
      "
    >
      <button
        class="
          w-1/2
          py-5
          rounded-lg
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
          border
          mr-3
        "
        :class="{
          'border-primary-color': autorenew === 'NO',
        }"
        @click="autorenew = 'NO'"
      >
        <div
          v-if="autorenew === 'NO'"
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img src="@/assets/images/check-mark.svg" alt="via delivery" />
        </div>
        <img
          v-else
          src="@/assets/images/checkbox-unchecked.svg"
          alt="via pickup"
        />
        <span class="font-semibold uppercase text-xs ml-1.5">no, one time</span>
      </button>
      <button
        class="
          w-1/2
          py-3
          rounded-lg
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
          border
          ml-3
        "
        :class="{ 'border-primary-color': autorenew === 'YES' }"
        @click="autorenew = 'YES'"
      >
        <div
          v-if="autorenew === 'YES'"
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img
            src="@/assets/images/check-mark.svg"
            alt="auto renew subscription"
          />
        </div>
        <img
          v-else
          src="@/assets/images/checkbox-unchecked.svg"
          alt="auto renew subscription"
        />
        <span class="font-semibold uppercase text-xs ml-1.5"
          >yes, auto renew</span
        >
      </button>
    </div>
    <div v-if="autorenew === 'NO'">
      <custom-input
        :inputData="{
          label: 'Duration of plan in months',
          fieldName: 'duration',
          placeHolder: 'Number of months',
        }"
        attributeType="number"
        @input-value="setValue"
      >
      </custom-input>
    </div>
    <div class="pb-5" v-if="autorenew === 'YES'">
      <span class="text-primary-color text-sm"
        ><b>Note:</b> Auto renew will occur monthly
      </span>
    </div>
    <div>
      <custom-input
        :inputData="{
          label: 'transaction pin',
          placeHolder: 'Enter your transaction pin',
          fieldName: 'pin',
        }"
        attributeType="password"
        @input-value="setValue"
      >
      </custom-input>
      <ValidationMessage v-if="loading && !$v.pin.required" class="-mt-6">
        <span slot="message">Enter your transaction pin</span>
      </ValidationMessage>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>
    <custom-button v-if="user" :loading="submitting" @sendAction="pay">
      <span slot="button-text">Pay</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'RenewSubscription',
  components: {
    XIcon,
  },
  mounted() {
    this.fetchSubscription()
    if (this.currentPlan) {
      this.autorenew = this.currentPlan.autorenew
    }
  },
  data() {
    return {
      showPlanOptions: false,
      loading: false,
      submitting: false,
      notification: null,
      pin: null,
      autorenew: 'NO',
      duration: 1,
    }
  },
  validations() {
    return {
      pin: { required },
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'user', 'currentPlan']),
  },
  methods: {
    setValue(value) {
      this[value.field] = value.value
    },
    async fetchSubscription() {
      try {
        const { status, data } = await this.axios.get('/account/subscription')

        if (status === 200) {
          this.selectedPlan = data.data.bundle
          this.$store.commit('SET_CURRENT_PLAN', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async pay() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        if (this.autorenew === 'YES') this.duration = 1
        this.submitting = true

        const { status } = await this.axios.patch('/account/subscription', {
          product_id: this.currentPlan.bundle_id,
          duration: this.duration,
          autorenew: this.autorenew.toUpperCase(),
          autorenew_rate: this.currentPlan.autorenew_rate,
          amount: this.currentPlan.price,
          pin: this.pin,
        })

        if (status === 200) {
          this.loading = false
          this.submitting = false
          this.$emit('renewalSuccess')
          this.fetchSubscription()
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
        this.loading = false
      }
    },
  },
}
</script>
