import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import store from '@/store/index'

// const token = store.getters.token

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: 'register' */ '@/views/Register.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: 'login' */ '@/views/Login.vue'),
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: 'verify' */ '@/views/VerifyAccount.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () =>
      import(/* webpackChunkName: 'forgot' */ '@/views/Forgot.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/password',
    name: 'Password',
    component: () =>
      import(/* webpackChunkName: 'password' */ '@/views/SetNewPassword.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/account/secure',
    name: 'CreatePassword',
    component: () =>
      import(/* webpackChunkName: 'password' */ '@/views/CreatePassword.vue'),
      meta: { requiresGuest: true },
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () =>
      import(/* webpackChunkName: 'subscriptions' */ '@/views/Subscriptions.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/reward/points',
    name: 'RewardPoints',
    component: () =>
      import(/* webpackChunkName: 'rewards' */ '@/views/RewardPoints.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () =>
      import(/* webpackChunkName: 'transactions' */ '@/views/Transactions.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/purchases',
    name: 'Purchases',
    component: () =>
      import(/* webpackChunkName: 'purchases' */ '@/views/Purchases.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/purchases/:purchaseCategory',
    name: 'PurchaseCategory',
    component: () =>
      import(/* webpackChunkName: 'purchasecategory' */ '@/views/PurchaseCategory.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/purchases/:purchaseCategory/:merchant',
    name: 'PurchaseCategoryMerchant',
    component: () =>
      import(/* webpackChunkName: 'purchasecategorymerchat' */ '@/views/PurchaseCategoryMerchant.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/purchases/:purchaseCategory/:merchant/:product',
    name: 'PurchaseView',
    component: () =>
      import(/* webpackChunkName: 'merchantViw' */ '@/views/SingleProductView.vue'),
      meta: { requiresAuth: true },
      props: true
  },
  {
    path: '/profile/:username',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: 'profile' */ '@/views/Profile.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/product/checkout',
    name: 'Checkout',
    component: () =>
      import(/* webpackChunkName: 'checkout' */ '@/views/Checkout.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/cart/checkout',
    name: 'CartCheckout',
    component: () =>
      import(/* webpackChunkName: 'checkout' */ '@/views/Checkout.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/electricity/token',
    name: 'ElectricityToken',
    component: () =>
      import(/* webpackChunkName: 'token' */ '@/views/ElectricityToken.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () =>
      import(/* webpackChunkName: 'payments' */ '@/views/Payments.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payments/direct-payment',
    name: 'DirectPayment',
    component: () =>
      import(/* webpackChunkName: 'directpayment' */ '@/views/DirectPayment.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payments/utilities',
    name: 'Utilities',
    component: () =>
      import(/* webpackChunkName: 'directpayment' */ '@/views/Utilities.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payments/legend-services',
    name: 'LegendServices',
    component: () =>
      import(/* webpackChunkName: 'legendpackages' */ '@/views/Subscriptions.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/family',
    name: 'Family',
    component: () =>
      import(/* webpackChunkName: 'family' */ '@/views/FamilyWallet.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payment/foreign/success',
    name: 'SuccessForeignPayment',
    component: () =>
      import(/* webpackChunkName: 'successforeignpayment' */ '@/views/SuccessForeignPayment.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payment/foreign/failure',
    name: 'FailureForeignPayment',
    component: () =>
      import(/* webpackChunkName: 'failureforeignpayment' */ '@/views/FailureForeignPayment.vue'),
      meta: { requiresAuth: true },
  },
  {
    path: '/payment/foreign/cancelled',
    name: 'CancelForeignPayment',
    component: () =>
      import(/* webpackChunkName: 'cancelforeignpayment' */ '@/views/CancelForeignPayment.vue'),
      meta: { requiresAuth: true },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  //Check for requiresAuth Guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //Check login status
    if (!store.getters.token) {
      //Take back to login if user isn't authenticated
      next({ path: '/login', query: { redirect: to.fullPath }, })
    } else {
      next()
    }
  }  else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (store.getters.token) {
      return router.go(-1)
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
