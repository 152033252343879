<template>
  <div
    class="
      text-legend-gray
      dark:text-border-color
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      pb-32
      px-8
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <div>
      <x-icon
        size="1.3x"
        class="custom-class mb-6 -ml-5 -mt-1 text-primary-color cursor-pointer"
        @click="$emit('closeModal')"
      ></x-icon>
      <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
        Buy Electricity
      </h1>
      <div class="my-3 text-sm">
        Top up your prepaid and never go out of power supply.
      </div>
    </div>
    <div class="my-6 relative">
      <div class="w-full relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Select Provider</label
        >
        <div
          class="
            plan-options
            flex
            items-center
            w-full
            border border-border-color
            rounded-lg
            py-3
            px-1
          "
        >
          <input
            v-model="selectedProvider.title"
            type="text"
            readonly
            class="
              w-full
              px-4
              bg-transparent
              focus:outline-none
              capitalize
              plan-options
            "
            @click="providerOptions = !providerOptions"
          />
        </div>
      </div>
      <img
        src="@/assets/images/down-chevron.svg"
        alt="select provider"
        class="w-3 absolute right-4 top-11 plan-options"
      />
      <ul
        v-click-outside="hideDropdown"
        v-if="providerOptions"
        class="
          absolute
          top-full
          w-full
          h-40
          overflow-y-scroll
          overscroll-y-contain
          bg-white
          border-gray-200
          shadow-md
        "
      >
        <li
          v-for="(provider, index) in providers"
          :key="index"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
          @click="selectProvider(provider)"
        >
          {{ provider.title }} ({{ provider.code }})
        </li>
      </ul>
    </div>
    <div class="text-legend-gray dark:text-border-color mb-8">
      <div class="font-semibold text-sm mb-1">Prepaid meter no.</div>
      <div
        class="
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          capitalize
        "
      >
        <input
          v-model.trim="meter_number"
          type="text"
          placeholder="Enter your prepaid meter number here."
          class="w-full bg-transparent focus:outline-none"
        />
      </div>
      <ValidationMessage v-if="loading && !$v.meter_number.required">
        <span slot="message">Meter number is required.</span>
      </ValidationMessage>
    </div>
    <div
      v-if="meter_details"
      class="text-legend-gray dark:text-border-color mb-8"
    >
      <div class="font-semibold text-sm mb-1">Meter name</div>
      <div
        class="
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          capitalize
        "
      >
        <input
          v-model.trim="meter_details.meter_name"
          type="text"
          class="w-full bg-transparent focus:outline-none"
        />
      </div>
    </div>
    <div v-if="meter_details" class="mb-8">
      <div
        class="
          font-semibold
          text-sm
          mb-1
          capitalize
          text-legend-gray
          dark:text-border-color
        "
      >
        amount
      </div>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model.lazy="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage v-if="loading && !amount">
        <span slot="message">Enter an amount</span>
      </ValidationMessage>
    </div>
    <div v-if="meter_details">
      <custom-input
        :inputData="{
          label: 'transaction pin',
          placeHolder: 'Enter your transaction pin',
          fieldName: 'pin',
        }"
        attributeType="password"
        @input-value="setValue"
      >
      </custom-input>
      <ValidationMessage v-if="loading && !pin" class="-mt-6">
        <span slot="message">Enter your transaction pin</span>
      </ValidationMessage>
    </div>
    <div v-if="user && !user.has_pin" class="flex items-center">
      <span>Don't have transaction pin?</span>
      <router-link
        :to="{ name: 'Profile', params: { username: user.username } }"
        class="text-primary-color font-semibold cursor-pointer capitalize ml-2"
      >
        Click here
      </router-link>
    </div>
    <custom-button
      v-if="!meter_details"
      :loading="submitting"
      @sendAction="verifyMeter"
    >
      <span slot="button-text"> Verify Meter Number</span>
    </custom-button>
    <custom-button v-if="meter_details" :loading="submitting" @sendAction="buy">
      <span slot="button-text">Buy Electricity</span>
    </custom-button>
  </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'BuyElectricity',
  components: {
    XIcon,
    CustomInput,
  },
  mounted() {
    this.meter_number = localStorage.getItem('lgp_utility___device.idty')
  },
  data() {
    return {
      isSaved: true,
      isNew: false,
      meter_number: null,
      meter_details: null,
      amount: null,
      pin: null,
      notification: null,
      selectedProvider: {
        title: 'Abuja electricity distribution company',
        name: 'abuja-electric',
        code: 'AEDC',
      },
      providers: [
        {
          title: 'Abuja electricity distribution company',
          name: 'abuja-electric',
          code: 'AEDC',
        },
        {
          title: 'kaduna electricity distibution company',
          name: 'kaduna-electric',
          code: 'KEDC',
        },
        {
          title: 'Jos electricity distribution company',
          name: 'jos-electric',
          code: 'JEDC',
        },
        {
          title: 'kano electricity distribution company',
          name: 'kano-electric',
          code: 'KAEDC',
        },
        {
          title: 'enugu electricity distribution company',
          name: 'enugu-electric',
          code: 'EEDC',
        },
        {
          title: 'ogun electricity distribution company',
          name: 'ogun-electric',
          code: 'OEDC',
        },
        {
          title: 'ibadan electricity distribution company',
          name: 'ibadan-electric',
          code: 'IEDC',
        },
      ],
      providerOptions: false,
      submitting: false,
      loading: false,
    }
  },
  validations() {
    return {
      meter_number: { required },
    }
  },
  computed: {
    ...mapGetters(['user']),
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter((option) => {
            return (
              option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
          }),
        },
      ]
    },
    phoneNumber() {
      return this.$store.getters.user.phone
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('plan-options')) {
        this.providerOptions = false
      }
    },
    selectProvider(provider) {
      this.selectedProvider = provider
      this.providerOptions = false
    },
    clickHandler() {
      this.selected = null
    },
    onSelected(item) {
      this.selected = item.item.phone_code
      this.selectedCountryFlag = item.item.href.flag
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    toggleSaved() {
      this.isSaved = true
      this.isNew = false
    },
    toggleNew() {
      this.isSaved = false
      this.isNew = true
    },
    setValue(payload) {
      this[payload.field] = payload.value
    },
    async verifyMeter() {
      try {
        if (this.submitting) {
          return
        }

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true
        localStorage.setItem('lgp_utility___device.idty', this.meter_number)
        const { status, data } = await this.axios.post(
          '/account/utility/electricity/verify',
          {
            provider: this.selectedProvider.name,
            meter_number: this.meter_number,
          }
        )

        if (status === 200) {
          this.meter_details = data.data
          this.submitting = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async buy() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        if (!this.amount || !this.pin) {
          return
        }

        this.submitting = true
        const { status, data } = await this.axios.post(
          '/account/utility/electricity/purchase',
          {
            provider: this.selectedProvider.name,
            meter_number: this.meter_number,
            meter_name: this.meter_details.meter_name,
            address: this.meter_details.address,
            amount: Number(this.amount),
            phone_number: this.phoneNumber,
            pin: this.pin,
          }
        )

        if (status === 200) {
          this.$router.push({
            name: 'ElectricityToken',
            query: { result: JSON.stringify(data.data) },
          })
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
        this.submitting = false
      }
    },
  },
}
</script>
