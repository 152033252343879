const state = {
    mode: 'dark',
  }
  
  const getters = {
    mode: (state) => state.mode,
  }
  
  const actions = {}
  
  const mutations = {
    SET_MODE: (state) => state.mode = 'dark',
    CHANGE_MODE: (state) => state.mode === 'dark' ? state.mode = 'light' : state.mode = 'dark'
  }

  export default {
    state,
    getters,
    actions,
    mutations,
  }