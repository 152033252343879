export default  (selector, mode) => {
    const nodes = document.querySelectorAll(selector)
    nodes.forEach((node, index) => {
      if (mode !== 'dark' && index % 2 === 0) {
        node.classList.remove('bg-legend-black')
        node.classList.add('bg-gray-100')
      } else if (mode === 'dark' && index % 2 === 0) {
        node.classList.remove('bg-gray-100')
        node.classList.add('bg-legend-black')
      }
    })
  }