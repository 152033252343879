<template>
  <div
    class="
      xl:w-80
      lg:w-80
      md:w-96
      w-full
      max-w-md
      text-white
      flex
      items-center
      justify-between
      px-5
      py-6
      mt-5
    "
  >
    <div class="flex items-center flex-shrink-0">
      <slot name="icon" />
    </div>
    <div class="w-full ml-6">
      <div class="mb-2 text-sm">
        <slot name="title" />
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="font-bold">
          <slot name="amount" />
        </div>
        <img
          v-if="!showBalance"
          src="@/assets/images/eye-icon-white.svg"
          alt="hide wallet balance"
          @click="$emit('toggleBalance', true)"
        />
        <img
          v-if="showBalance"
          src="@/assets/images/eye-closed.svg"
          alt="show wallet balance"
          @click="$emit('toggleBalance', false)"
        />
        <span
          class="
            bg-white
            py-2
            px-5
            rounded-full
            font-semibold
            text-sm
            cursor-pointer
          "
          @click="triggerAction"
        >
          <slot name="action" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardCard',
  props: {
    showBalance: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchWalletBalance()
    this.fetchRewardPoints()
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints']),
  },
  methods: {
    triggerAction(event) {
      if (event.target.textContent.toLowerCase() === 'redeem') {
        return this.$router.push({ name: 'RewardPoints' })
      } else if (event.target.textContent.toLowerCase() === 'top up') {
        this.$store.commit('SET_FUND_WALLET', true)
      }
    },
    async fetchWalletBalance() {
      try {
        const { status, data } = await this.axios.get('/account/wallet')
        if (status === 200) {
          this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        }
      }
    },
    async fetchRewardPoints() {
      try {
        const { status, data } = await this.axios.get('/account/rewards')

        if (status === 200) {
          this.$store.commit('SET_REWARD_POINTS', data.data.point)
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        }
      }
    },
  },
}
</script>
