import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import cart from './modules/cart'
import theme from './modules/theme'
import product from './modules/product'

//Load Vuex
Vue.use(Vuex)

//Create store
export default new Vuex.Store({
  modules: {
    user, cart, theme, product
  },
  plugins: [createPersistedState({
    key: 'LP-customer',
  })],
})
