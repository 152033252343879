<template>
  <main class="w-full overflow-x-scroll">
    <div
      class="
        table
        w-full
        bg-white
        dark:bg-dark-mode-primary dark:text-border-color
        text-sm
        rounded-md
        shadow-lg
        mt-6
      "
    >
      <div v-if="transactions && transactions.length" class="table-row-group">
        <div class="table-row">
          <div class="table-cell py-4 px-5 font-semibold">SN</div>
          <div class="table-cell py-4 px-5 font-semibold">Type</div>
          <div class="table-cell py-4 px-5 font-semibold" style="width: 280px">
            Description
          </div>
          <div class="table-cell py-4 px-5 font-semibold">Reference Number</div>
          <div class="table-cell py-4 px-5 font-semibold">Amount</div>
          <div class="py-4 px-5 font-semibold" style="width: 120px">Date</div>
          <div class="table-cell py-4 px-5 font-semibold">Status</div>
          <div class="table-cell py-4 px-5 font-semibold">Action</div>
        </div>
        <div
          v-for="(transaction, index) in transactions"
          :key="index"
          class="
            data-items
            table-row
            text-legend-gray
            dark:text-border-color dark:border-b dark:border-border-color
          "
        >
          <div class="table-cell py-4 px-5">{{ index + 1 }}</div>
          <div class="table-cell py-4 px-5">{{ transaction.type }}</div>
          <div class="table-cell py-4 px-5">{{ transaction.description }}</div>
          <div class="table-cell py-4 px-5">
            {{ transaction.ref || transaction.reference }}
          </div>
          <div class="table-cell py-4 px-5 text-secondary-green">
            ₦{{ Number(transaction.amount) | moneyFormat }}
          </div>
          <div class="table-cell py-4 px-5">
            {{ transaction.date | moment('MMM D, YYYY') }} •
            {{ transaction.date | moment('h:mm a') }}
          </div>
          <div class="table-cell py-4 px-5">
            <span
              v-if="transaction.status === 'Success'"
              style="background-color: #d3eade"
              class="
                text-xs text-secondary-green
                inline-block
                w-16
                py-2
                text-center
                rounded-full
                font-semibold
              "
              >{{ transaction.status }}</span
            >
            <span
              v-else
              class="
                text-xs text-primary-red
                inline-block
                w-16
                bg-red-100
                py-2
                text-center
                rounded-full
                font-semibold
              "
              >{{ transaction.status }}</span
            >
          </div>
          <div
            class="
              table-cell
              py-4
              px-5
              font-semibold
              text-primary-color
              cursor-pointer
            "
            @click="showSingleTransaction(transaction)"
          >
            <span v-if="transaction.type != 'Recharge'">View</span>
          </div>
        </div>
        <LoadingSpinner v-if="!transactions" />
      </div>
    </div>
    <custom-modal v-if="viewingSingle">
      <TransactionReceipt
        slot="cart"
        @closeModal="viewingSingle = false"
        :singleTransaction="singleTransaction"
      />
    </custom-modal>
    <div
      v-if="transactions && !transactions.length"
      class="text-center font-medium text-lg mt-14"
    >
      No transactions yet.
    </div>
  </main>
</template>

<script>
import TransactionReceipt from '@/components/receipts/ReceiptScaffold.vue'
import stripeTable from '@/util/stripeTable'
import { mapGetters } from 'vuex'

export default {
  components: {
    TransactionReceipt,
  },
  name: 'DataTable',
  props: {
    transactions: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['mode']),
  },
  mounted() {
    stripeTable('.data-items', this.mode)
  },
  watch: {
    mode() {
      stripeTable('.data-items', this.mode)
    },
  },
  data() {
    return {
      viewingSingle: false,
      singleTransaction: null,
    }
  },
  methods: {
    showSingleTransaction(transaction) {
      this.singleTransaction = transaction
      this.viewingSingle = true
    },
  },
}
</script>
