const state = {
    cart: []
  }
  
  const getters = {
    cart: (state) => state.cart,
  }
  
  const actions = {}
  
  const mutations = {
    SET_CART: (state, cart) => (state.cart = cart),
    REMOVE_ITEM: (state, itemId) => {
        const item = state.cart.find((tick) => tick.id === itemId)
        const index = state.cart.indexOf(item)
        state.cart.splice(index, 1)
    },
    UPDATE_CART: (state, payload) => state.cart.push(payload),
    DECREMENT_COUNT: (state, itemId) => {
        const item = state.cart.find(tick => tick.id === itemId)

        if(item.quantity > 1) {
            item.quantity--
        }
    },
    INCREMENT_COUNT: (state, itemId) => {
        const item = state.cart.find(tick => tick.id === itemId)

        item.quantity++
    }
  }
  
  export default {
    state,
    getters,
    actions,
    mutations,
  }