<template>
  <section class="text-legend-gray text-sm mt-12 relative">
    <div class="flex items-center justify-between">
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Transaction ID
        </div>
        <div class="text-xs">
          {{ singleTransaction.reference }}
        </div>
      </div>
      <img :src="getImgUrl()" alt="" class="provider-logo w-12" />
    </div>
    <div class="my-5 w-full h-1 bg-primary-color bg-opacity-50">
      <div class="w-1/2 h-full bg-primary-color"></div>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Network provider name</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.product
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Account name</span>
      <span
        class="
          font-semibold
          capitalize
          text-legend-black
          dark:text-border-color
        "
        >{{ user.first_name }} {{ user.last_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Phone number</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.phone || 'NA'
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Date and time</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.date
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Transaction status</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.status
      }}</span>
    </div>
    <div
      class="
        my-14
        xl:mx-14
        lg:mx-14
        md:mx-14
        mx-8
        flex
        items-center
        justify-center
        bg-secondary-green bg-opacity-10
        text-secondary-green
        p-2
        rounded-lg
        font-bold
        text-lg
      "
    >
      <span class="mr-2">Recharge Amount: </span>
      <span>₦{{ singleTransaction.amount | moneyFormat }}</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AirtimeReceipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    getImgUrl() {
      let filePath

      if (
        this.singleTransaction.product.toLowerCase() === '9mobile' ||
        this.singleTransaction.product.toLowerCase() === 'etisalat'
      ) {
        filePath = '9mobile-logo.svg'
      } else if (this.singleTransaction.product.toLowerCase().includes('mtn')) {
        filePath = 'mtn-logo.svg'
      } else if (this.singleTransaction.product.toLowerCase() === 'glo') {
        filePath = 'globacom-logo.svg'
      } else if (this.singleTransaction.product.toLowerCase() === 'airtel') {
        filePath = 'airtel-logo.svg'
      }

      return require(`@/assets/images/${filePath}`)
    },
  },
}
</script>
