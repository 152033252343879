const state = {
    categoryId: null,
    currentMerchant: null,
  }
  
  const getters = {
    categoryId: (state) => state.categoryId,
    currentMerchant: (state) => state.currentMerchant,
  }
  
  const mutations = {
    SET_CATEGORY_ID: (state, id) => state.categoryId = id,
    RESET_CATEGORY_ID: (state) => state.categoryId = null,
    SET_CURRENT_MERCHANT: (state, data) => state.currentMerchant = data
  }

  export default {
    state,
    getters,
    mutations,
  }