<template>
  <div class="
      text-legend-gray
      dark:text-border-color
      xl:px-10
      lg:px-10
      md:px-8
      px-6
      pt-4
      pb-24
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      z-50
    ">
    <NotificationToast v-if="notification" :notification="notification" @resetNotification="notification = null" />
    <x-icon size="1.5x" class="
        custom-class
        mb-4
        -ml-4
        -mt-2
        text-primary-color
        cursor-pointer
        sticky
        top
      " @click="$store.commit('SET_FUND_WALLET', false)"></x-icon>
    <div class="
        w-80
        bg-primary-color
        text-white
        flex
        items-end
        justify-between
        px-5
        py-6
        mt-5
        mb-16
      ">
      <div class="flex items-center">
        <img src="@/assets/images/subscription-icon-light.svg" alt="go to subscriptions" class="w-8" />
        <div class="ml-5">
          <div>Wallet Balance</div>
          <div class="font-bold mt-2">₦{{ walletBalance | moneyFormat }}</div>
        </div>
      </div>
    </div>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Fund Wallet
    </h1>
    <div class="my-3 text-sm">
      Top up your wallet balance to enjoy seamless transaction and uninterrupted
      activity on your legend pay.
    </div>
    <div class="mt-6 mb-3 font-semibold">How do you want to pay?</div>
    <div class="flex-column justify-between text-legend-gray uppercase mb-12">
      <div class="grid grid-cols-2 gap-7">
        <div class="
            p-5
            rounded-lg
            shadow-lg
            bg-white
            dark:bg-dark-mode-primary
            flex
            items-center
          " :class="{ 'border  border-primary-color': isVoucher }" @click="payViaVoucher">
          <img v-if="!isVoucher" src="@/assets/images/checkbox-unchecked.svg" alt="via voucher" />
          <div v-else style="width: 18px; height: 18px"
            class="bg-primary-color rounded flex items-center justify-center">
            <img src="@/assets/images/check-mark.svg" alt="via bank transfer" />
          </div>
          <span class="font-bold text-xs ml-2 dark:text-border-color">Via Voucher</span>
        </div>
        <div class="
            p-5
            rounded-lg
            shadow-lg
            bg-white
            dark:bg-dark-mode-primary
            flex
            items-center
          " :class="{ 'border  border-primary-color': isTransfer }" @click="payViaBankTransfer">
          <div v-if="isTransfer" style="width: 18px; height: 18px"
            class="bg-primary-color rounded flex items-center justify-center">
            <img src="@/assets/images/check-mark.svg" alt="via bank transfer" />
          </div>
          <img v-else src="@/assets/images/checkbox-unchecked.svg" alt="via voucher" />
          <span class="font-bold text-xs ml-2 dark:text-border-color">Legend Pay</span>
        </div>
        <!-- <div
          class="
            p-5
            rounded-lg
            shadow-lg
            bg-white
            dark:bg-dark-mode-primary
            flex
            items-center
          "
          :class="{ 'border  border-primary-color': isQuadropay }"
          @click="payViaQuadropay"
        >
          <div
            v-if="isQuadropay"
            style="width: 18px; height: 18px"
            class="bg-primary-color rounded flex items-center justify-center"
          >
            <img src="@/assets/images/check-mark.svg" alt="via bank transfer" />
          </div>
          <img
            v-else
            src="@/assets/images/checkbox-unchecked.svg"
            alt="via voucher"
          />
          <span class="font-bold text-xs ml-2 dark:text-border-color"
            >Quadro Pay</span
          >
        </div> -->
      </div>
    </div>
    <div v-if="isVoucher" class="my-8">
      <div class="text-sm">
        Kindly scan your voucher qr code or enter the legend pay voucher
        information below.
      </div>
      <button class="
          text-white
          font-semibold
          text-lg
          bg-primary-color
          rounded-lg
          py-3
          flex
          w-full
          items-center
          justify-between
          px-8
          my-8
          cursor-pointer
        " :class="{ 'bg-main-border cursor-not-allowed': scanningQR }" @click="scanningQR = true">
        <span>Scan Voucher QR code</span>
        <img src="@/assets/images/qr-code.svg" alt="Scan Voucher QR code" />
      </button>
      <div v-if="scanningQR" class="w-48 mx-auto mt-5 relative">
        <qrcode-stream @detect="onDetect"></qrcode-stream>
        <div class="
            absolute
            -top-6
            -right-6
            border border-primary-color
            rounded-full
            p-1
          ">
          <x-icon size="1x" class="custom-class text-primary-color cursor-pointer" @click="scanningQR = false"></x-icon>
        </div>
      </div>
      <div class="border-t border-gray-300 mx-8 my-11">
        <div class="
            -mt-3
            mx-auto
            capitalize
            w-12
            bg-primary-background
            dark:bg-dark-mode-main
            text-center
          ">
          or
        </div>
      </div>
      <div class="relative">
        <custom-input :inputData="{
          label: 'Voucher serial no',
          placeHolder: 'Enter your voucher serial number',
          fieldName: 'serial_number',
        }" id="voucher-serial" @input-value="setValue">
        </custom-input>
        <ValidationMessage v-if="loading && !$v.serial_number.required" class="absolute top-16">
          <span slot="message">Voucher serial number is required.</span>
        </ValidationMessage>
      </div>
      <div class="relative">
        <custom-input :inputData="{
          label: 'Voucher pin',
          placeHolder: 'Enter your voucher pin',
          fieldName: 'pin',
        }" id="voucher-pin" @input-value="setValue">
        </custom-input>
        <ValidationMessage v-if="loading && !$v.pin.required" class="absolute top-16">
          <span slot="message">Voucher pin is required.</span>
        </ValidationMessage>
      </div>
    </div>
    <div v-else class="relative">
      <div class="
          font-semibold
          text-sm
          mb-1
          capitalize
          text-legend-gray
          dark:text-border-color
        ">
        amount
      </div>
      <currency-input type="text" placeholder="Enter amount" v-model="amount" currency="NGN" locale="en" class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        " />

      <ValidationMessage v-if="loading && !amount > 0" class="absolute top-16">
        <span slot="message">You must enter an amount to pay.</span>
      </ValidationMessage>
    </div>
    <!-- <section
      v-if="isQuadropay"
      class="text-legend-gray dark:text-border-color mb-8 relative my-6"
    >
      <label class="font-semibold text-sm mb-1">Currency</label>
      <input
        v-model="selectedCurrency"
        type="text"
        readonly
        placeHolder="Select currency"
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
          capitalize
        "
        @click="currencyOptions = !currencyOptions"
      />
      <chevron-down-icon
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          absolute
          right-2
          top-9
        "
        @click="currencyOptions = !currencyOptions"
      ></chevron-down-icon>
      <ul
        v-if="currencyOptions"
        class="
          border border-gray-200
          shadow-md
          rounded-md
          bg-white
          dark:bg-legend-black dark:text-border-color
          absolute
          left-0
          top-full
          w-full
          z-50
          max-h-48
          overflow-y-scroll
          overscroll-contain
        "
      >
        <li
          v-for="(currency, index) in currencies"
          :key="index"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
          @click="selectCurrency(currency, index)"
        >
          {{ currency }}
        </li>
      </ul>
      <ValidationMessage v-if="loading && !$v.selectedCurrency.required">
        <span slot="message">Currency is required</span>
      </ValidationMessage>
    </section> -->
    <!-- <div v-if="isQuadropay" class="my-6">
      <div class="text-legend-gray dark:text-border-color capitalize w-full">
        <label class="font-semibold text-sm mb-1">transaction pin</label>
        <input
          v-model="transactionPin"
          type="password"
          placeHolder="Enter your transaction pin"
          class="
            phone-number
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
      <ValidationMessage v-if="loading && !$v.transactionPin.required">
        <span slot="message">Transaction pin is required.</span>
      </ValidationMessage>
    </div> -->
    <div class="grid grid-cols-2 gap-20 mt-16 mb-24">
      <!-- <div
        class="
          rounded-md
          p-4
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
        "
        :class="{
          'border  border-primary-color': paymentGateway === 'flutterwave',
        }"
        @click="paymentGateway = 'flutterwave'">
        <img
          v-if="paymentGateway !== 'flutterwave'"
          src="@/assets/images/checkbox-unchecked.svg"
          alt="via voucher"
        />
        <div
          v-else
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img src="@/assets/images/check-mark.svg" alt="via bank transfer" />
        </div>
        <span class="font-bold text-xs ml-2 dark:text-border-color"
          >Flutterwave</span
        >
      </div>
      <div
        class="
          p-4
          rounded-lg
          shadow-lg
          bg-white
          dark:bg-dark-mode-primary
          flex
          items-center
          justify-center
        "
        :class="{
          'border  border-primary-color': paymentGateway === 'paystack',
        }"
        @click="paymentGateway = 'paystack'"
      >
        <div
          v-if="paymentGateway === 'paystack'"
          style="width: 18px; height: 18px"
          class="bg-primary-color rounded flex items-center justify-center"
        >
          <img src="@/assets/images/check-mark.svg" alt="via bank transfer" />
        </div>
        <img
          v-else
          src="@/assets/images/checkbox-unchecked.svg"
          alt="via voucher"
        />
        <span class="font-bold text-xs ml-2 dark:text-border-color"
          >Paystack</span
        >
      </div> -->
    </div>
    <custom-button :loading="submitting" @sendAction="fundWallet">
      <span slot="button-text">Fund Wallet</span>
    </custom-button>
    <!-- Paystack button -->
    <div class="hidden">
      <paystack :amount="Number(amount) * 100" :email="user.email" :paystackkey="paystackkey" :reference="reference"
        :channels="['bank', 'card', 'bank_transfer', 'qr', 'ussd']" :callback="callback" :close="closedPaymentModal"
        :embed="false">
      </paystack>
    </div>
    <div class="hidden">
      <flutterwave-pay-button :tx_ref="referenceData" :amount="amount" currency="NGN" payment_options="card,ussd"
        class="pay-button" :customer="{
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone_number: `${user.country_code}${user.phone}`,
        }" :customizations="{
          title: 'Legend',
          description: '',
          logo: 'https://res.cloudinary.com/greeanbrain/image/upload/v1634822304/suburban-sales-automation-assets/legend-logo-mini.svg',
        }" :callback="makePaymentCallback" :onclose="closedPaymentModal">
        Click To Pay
      </flutterwave-pay-button>
    </div>
  </div>
</template>

<script>
// import { created } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { XIcon } from 'vue-feather-icons'
import { required } from 'vuelidate/lib/validators'
import paystack from 'vue-paystack'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'FundWallet',
  components: {
    XIcon,
    QrcodeStream,
    paystack,
  },
  mounted() {
    this.getCurrencies()
  },
  watch: {
    amount(newValue) {
      this.updateValue(newValue)
    },
  },
  data() {
    return {
      paymentGateway: 'flutterwave',
      isVoucher: false,
      isTransfer: true,
      isQuadropay: false,
      scanningQR: false,
      loading: false,
      submitting: false,
      amount: 0,
      serial_number: null,
      pin: null,
      transactionPin: null,
      notification: null,
      noAmount: false,
      paystackkey: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
      currencies: null,
      selectedCurrency: null,
      currencyOptions: false,
      currency: 'NGN',
    }
  },
  created() {
    this.generateReference();
  },
  validations() {
    if (this.isTransfer && !this.isVoucher) {
      return {
        amount: { required },
      }
    } else if (!this.isTransfer && this.isVoucher) {
      return {
        serial_number: { required },
        pin: { required },
      }
    } else if (!this.isTransfer && !this.isVoucher && this.isQuadropay) {
      return {
        amount: { required },
        transactionPin: { required },
        selectedCurrency: { required },
      }
    }
  },
  // created() {
  //   this.$store.dispatch('setTransactionData', {
  //     ref: this.generateReference(),
  //     currency: this.currency,
  //     amount: this.newValue,
  //   })
  // },
  computed: {
    ...mapGetters(['user', 'walletBalance']),
    reference() {
      // generate transaction reference for paystack
      let text = ''
      let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))

      return text
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    callback(response) {
      console.log(response)
      this.$nextTick(() => {
        this.savePaymentRecord({
          amount: this.amount,
          reference: response.reference,
        })
      })
    },
    makePaymentCallback(response) {
      this.closePaymentModal()

      this.$nextTick(() => {
        this.savePaymentRecord({
          amount: response.amount,
          reference: response.transaction_id.toString(),
        })
      })
    },
    updateValue(newValue) {
      this.$store.dispatch('setTransactionData', {
        ref: this.referenceData,
        currency: this.currency,
        amount: newValue,
      });

    },
    closedPaymentModal() {
      this.notification = {
        type: 'info',
        message: 'Transaction cancelled',
      }
      this.submitting = false
      this.loading = false
    },

    // generateReference() {
    //   let date = new Date()
    //   let referenceData = date.getTime().toString()
    //   return referenceData
    // },

    generateReference() {
      let date = new Date();
      let referenceData = date.getTime().toString();
      this.referenceData = referenceData;
    },
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('currency-options')) {
        this.currencyOptions = false
      }
    },
    payViaBankTransfer() {
      this.loading = false
      this.isTransfer = true
      this.isVoucher = false
      this.isQuadropay = false
      this.submitting = false
    },
    payViaVoucher() {
      this.loading = false
      this.isTransfer = false
      this.isVoucher = true
      this.isQuadropay = false
      this.submitting = false
    },
    payViaQuadropay() {
      this.loading = false
      this.isTransfer = false
      this.isVoucher = false
      this.isQuadropay = true
      this.submitting = false
    },
    selectCurrency(currency, index) {
      this.selectedCurrency = currency
      this.selectCurrencyCode = index
      this.currencyOptions = false
    },
    async getCurrencies() {
      const { data } = await this.axios.get(
        '/account/wallet/foreign/currencies'
      )
      this.currencies = data.data
    },
    async onDetect(promise) {
      const {
        content, //decoded String or null
      } = await promise

      if (content === null) {
        this.notification = {
          type: 'info',
          message: 'nothing decoded. Try again.',
        }
      } else {
        document.querySelector('#voucher-serial input').value = content
        this.serial_number = content
        document.querySelector('#voucher-pin input').value = content
        this.pin = content
        this.scanningQR = false
      }
    },
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    fundWithBank() {
      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      if (!this.amount > 0) {
        return
      }

      this.submitting = true

      const selector =
        this.paymentGateway === 'paystack' ? 'payButton' : 'pay-button'

      const node = document.querySelector(`.${selector}`)
      node.click()
    },
    async fundWithQuadropay() {
      const baseUrl = window.location.origin

      if (this.submitting) {
        return
      }

      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      if (!this.amount > 0) {
        return
      }

      this.submitting = true

      const { status, data } = await this.axios.put('/account/wallet', {
        funding_method: 'international',
        amount: this.amount,
        pin: this.transactionPin,
        currency: this.selectCurrencyCode,
        success_url: `${baseUrl}/payment/foreign/success`,
        failure_url: `${baseUrl}/payment/foreign/failure`,
        cancel_url: `${baseUrl}/payment/foreign/cancelled`,
      })

      if (status === 200) {
        localStorage.setItem('trn_trn-qdr', data.message.id)
        const anchor = document.createElement('a')
        anchor.href = data.message.links[0].href
        anchor.click()
      }
    },
    async savePaymentRecord({ amount, reference }) {
      try {
        this.$store.commit('SET_FUND_WALLET', false)
        this.$store.commit('SET_TRANSACTION_STATUS', true)

        const url =
          this.paymentGateway === 'paystack'
            ? '/account/wallet/fund/paystack'
            : '/account/wallet'

        const { status } = await this.axios.put(url, {
          funding_method: 'wallet',
          amount,
          reference,
        })

        if (status === 200) {
          this.$store.commit('SET_TRANSACTION_STATUS', false)
          this.fetchWalletBalance()
        }
      } catch (error) {
        this.$store.commit('SET_TRANSACTION_STATUS', false)
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.loading = false
        this.submitting = false
      }
    },
    async fetchWalletBalance() {
      try {
        const { status, data } = await this.axios.get('/account/wallet')

        if (status === 200) {
          this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fundWithVoucher() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.put('/account/wallet', {
          funding_method: 'voucher',
          serial_number: this.serial_number,
          pin: this.pin,
        })

        if (status === 200) {
          this.serial_number = null
          this.pin = null
          this.transactionPin = null
          this.$emit('utility-purchase', {
            type: 'success',
            message: data.message,
          })
          this.submitting = false
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
      this.submitting = false
    },
    async fundWallet() {
      if (this.submitting) {
        return
      }
      const eligibilityStatus = await this.checkEligibility()
      const initializeLog = await this.initializeLog()
      if (eligibilityStatus != 200 || initializeLog != 200) {
        return
      }

      if (this.isTransfer) {
        // console.log(this.$store.state.user.currency),
        //   console.log(this.$store.state.user.tx_ref),
        //   console.log(this.$store.state.user.amount)
        this.fundWithBank()
      } else if (this.isVoucher) {
        this.fundWithVoucher()
      } else if (this.isQuadropay) {
        this.fundWithQuadropay()
      }
    },
    async checkEligibility() {
      try {
        this.submitting = true

        const { status } = await this.axios.get('/fund-wallet-eligibility')

        this.submitting = false

        return status
      } catch (error) {
        this.submitting = false
        this.$emit('closeModal')
        this.notification = {
          type: 'error',
          message: error.response.data.message,
        }
      }
    },
    async initializeLog() {
      // const referenceData = this.generateReference();
      console.log(this.reference);
      try {
        this.submitting = true
        const { status } = await this.axios.post(
          '/account/wallet/initialize/log',
          {
            funding_method: 'wallet',
            amount: this.amount,
            currency: this.currency,
            reference: this.referenceData,
          }
        )
        console.log(this.$store.state.user.currency)
        console.log(this.referenceData)
        this.submitting = false
        return status
      } catch (error) {
        this.submitting = false
        this.$emit('closeModal')
        this.notification = {
          type: 'error',
          message: error.response.data.message,
        }
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>
