var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('section',{staticClass:"\n      text-legend-gray\n      dark:text-border-color\n      px-12\n      h-screen\n      overflow-y-scroll\n      overscroll-contain\n      pb-16\n      flex flex-col\n      justify-between\n    "},[_c('section',[_c('x-icon',{staticClass:"\n          custom-class\n          mb-4\n          -ml-9\n          -mt-1\n          text-primary-color\n          cursor-pointer\n        ",attrs:{"size":"1.3x"},on:{"click":function($event){return _vm.$emit('closeModal')}}}),_c('img',{attrs:{"src":require("@/assets/images/legend-pay-logo-dark.svg"),"alt":"Legend pay logo"}}),_c('div',{staticClass:"\n          text-legend-gray\n          dark:text-border-color\n          text-xs\n          mt-4\n          capitalize\n        "},[_vm._v(" No 15, Bangui Street, Wuse 2, Abuja FCT. ")]),_c('button',{staticClass:"\n          text-primary-color\n          border border-primary-color\n          rounded-md\n          py-2\n          px-3\n          capitalize\n          flex\n          items-center\n          bg-white\n          absolute\n          right-8\n          top-5\n          text-sm\n          z-50\n        ",class:{ 'inactive-state': _vm.downloading },on:{"click":_vm.generateReport}},[_c('span',{staticClass:"mr-1"},[_vm._v("Download")]),_c('download-icon',{staticClass:"custom-class",attrs:{"size":"1x"}})],1),(
          _vm.singleTransaction.type.toLowerCase().includes('airtime') ||
          _vm.singleTransaction.type.toLowerCase().includes('data')
        )?_c('AirtimeReceipt',{staticClass:"dark:text-border-color",attrs:{"singleTransaction":_vm.singleTransaction}}):(_vm.singleTransaction.type.toLowerCase() === 'direct-pay')?_c('DirectPayment',{staticClass:"dark:text-border-color",attrs:{"singleTransaction":_vm.singleTransaction}}):(_vm.singleTransaction.type.toLowerCase().includes('electric'))?_c('ElectricityReceipt',{staticClass:"dark:text-border-color",attrs:{"singleTransaction":_vm.singleTransaction}}):(
          _vm.singleTransaction.type.toLowerCase().includes('bank') ||
          _vm.singleTransaction.type.toLowerCase().includes('charge')
        )?_c('WithdrawalReceipt',{staticClass:"dark:text-border-color",attrs:{"singleTransaction":_vm.singleTransaction}}):(_vm.singleTransaction.type.toLowerCase().includes('transfer'))?_c('WalletTransferReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}):_c('GeneralReceipt',{staticClass:"dark:text-border-color",attrs:{"singleTransaction":_vm.singleTransaction}})],1),_vm._m(0)]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1045,"filename":"Transaction Receipt","pdf-quality":2,"manual-pagination":false,"pdf-format":"a5","pdf-content-width":"550px","pdf-orientation":"portrait"},on:{"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()}}},[_c('section',{staticClass:"text-legend-black px-8 pt-6",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('img',{attrs:{"src":require("@/assets/images/legend-pay-logo-dark.svg"),"alt":"Legend pay logo"}}),_c('div',{staticClass:"text-legend-gray text-xs mt-4 capitalize"},[_vm._v(" No 15, Bangui Street, Wuse 2, Abuja FCT. ")]),(_vm.singleTransaction.type.toLowerCase().includes('airtime'))?_c('AirtimeReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}):(
          _vm.singleTransaction.type.toLowerCase().includes('direct-pay')
        )?_c('DirectPayment',{attrs:{"singleTransaction":_vm.singleTransaction}}):(_vm.singleTransaction.type.toLowerCase().includes('electric'))?_c('ElectricityReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}):(
          _vm.singleTransaction.type.toLowerCase().includes('bank') ||
          _vm.singleTransaction.type.toLowerCase().includes('charge')
        )?_c('WithdrawalReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}):(_vm.singleTransaction.type.toLowerCase().includes('transfer'))?_c('WalletTransferReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}):_c('GeneralReceipt',{attrs:{"singleTransaction":_vm.singleTransaction}}),_c('hr',{staticClass:"mt-2"}),_c('div',{staticClass:"flex pt-3 justify-between items-center text-xs"},[_c('div',[_c('a',{attrs:{"href":"https://legend.ng"}},[_vm._v("legendpay.ng")])]),_c('div',{staticClass:"flex items-center"},[_c('div',[_c('a',{attrs:{"href":"mailto:experience@legend.ng"}},[_vm._v(" experience@legend.ng")])]),_c('div',{staticClass:"text-primary-color ml-3 px-2 py-1"},[_c('a',{attrs:{"href":"tel:0700-MY-LEGEND"}},[_vm._v(" 0700-MY-LEGEND ")])])])])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('hr',{staticClass:"mt-2"}),_c('div',{staticClass:"\n          flex\n          xl:flex-row\n          lg:flex-row\n          md:flex-row\n          flex-col\n          pt-3\n          justify-between\n          items-center\n          text-xs\n        "},[_c('div',[_c('a',{attrs:{"href":"https://legend.ng"}},[_vm._v("legendpay.ng")])]),_c('div',{staticClass:"flex items-center"},[_c('div',[_c('a',{attrs:{"href":"mailto:experience@legend.ng"}},[_vm._v(" experience@legend.ng")])]),_c('div',{staticClass:"text-primary-color ml-3 px-2 py-1"},[_c('a',{attrs:{"href":"tel:0700-MY-LEGEND"}},[_vm._v(" 0700-MY-LEGEND ")])])])])])}]

export { render, staticRenderFns }