<template>
  <main>
    <section
      class="
        text-legend-gray
        dark:text-border-color
        px-12
        h-screen
        overflow-y-scroll
        overscroll-contain
        pb-16
        flex flex-col
        justify-between
      "
    >
      <section>
        <x-icon
          size="1.3x"
          class="
            custom-class
            mb-4
            -ml-9
            -mt-1
            text-primary-color
            cursor-pointer
          "
          @click="$emit('closeModal')"
        ></x-icon>

        <img
          src="@/assets/images/legend-pay-logo-dark.svg"
          alt="Legend pay logo"
        />
        <div
          class="
            text-legend-gray
            dark:text-border-color
            text-xs
            mt-4
            capitalize
          "
        >
          No 15, Bangui Street, Wuse 2, Abuja FCT.
        </div>

        <button
          @click="generateReport"
          class="
            text-primary-color
            border border-primary-color
            rounded-md
            py-2
            px-3
            capitalize
            flex
            items-center
            bg-white
            absolute
            right-8
            top-5
            text-sm
            z-50
          "
          :class="{ 'inactive-state': downloading }"
        >
          <span class="mr-1">Download</span>
          <download-icon size="1x" class="custom-class"></download-icon>
        </button>

        <AirtimeReceipt
          v-if="
            singleTransaction.type.toLowerCase().includes('airtime') ||
            singleTransaction.type.toLowerCase().includes('data')
          "
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        />
        <DirectPayment
          v-else-if="singleTransaction.type.toLowerCase() === 'direct-pay'"
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        />
        <!-- <ProductPurchase
          v-else-if="singleTransaction.type.toLowerCase() === 'product'"
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        /> -->
        <ElectricityReceipt
          v-else-if="singleTransaction.type.toLowerCase().includes('electric')"
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        />
        <WithdrawalReceipt
          v-else-if="
            singleTransaction.type.toLowerCase().includes('bank') ||
            singleTransaction.type.toLowerCase().includes('charge')
          "
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        />
        <WalletTransferReceipt
          v-else-if="singleTransaction.type.toLowerCase().includes('transfer')"
          :singleTransaction="singleTransaction"
        />
        <GeneralReceipt
          v-else
          :singleTransaction="singleTransaction"
          class="dark:text-border-color"
        />
      </section>
      <section>
        <hr class="mt-2" />
        <div
          class="
            flex
            xl:flex-row
            lg:flex-row
            md:flex-row
            flex-col
            pt-3
            justify-between
            items-center
            text-xs
          "
        >
          <div><a href="https://legend.ng">legendpay.ng</a></div>
          <div class="flex items-center">
            <div>
              <a href="mailto:experience@legend.ng"> experience@legend.ng</a>
            </div>
            <div class="text-primary-color ml-3 px-2 py-1">
              <a href="tel:0700-MY-LEGEND"> 0700-MY-LEGEND </a>
            </div>
          </div>
        </div>
      </section>
    </section>
    <!-- Download receipt -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1045"
      filename="Transaction Receipt"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-content-width="550px"
      pdf-orientation="portrait"
      @hasStartedGeneration="hasStartedGeneration()"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="text-legend-black px-8 pt-6">
        <img
          src="@/assets/images/legend-pay-logo-dark.svg"
          alt="Legend pay logo"
        />
        <div class="text-legend-gray text-xs mt-4 capitalize">
          No 15, Bangui Street, Wuse 2, Abuja FCT.
        </div>
        <AirtimeReceipt
          v-if="singleTransaction.type.toLowerCase().includes('airtime')"
          :singleTransaction="singleTransaction"
        />
        <DirectPayment
          v-else-if="
            singleTransaction.type.toLowerCase().includes('direct-pay')
          "
          :singleTransaction="singleTransaction"
        />
        <!-- <ProductPurchase
          v-else-if="singleTransaction.type.toLowerCase() === 'product'"
          :singleTransaction="singleTransaction"
        /> -->
        <ElectricityReceipt
          v-else-if="singleTransaction.type.toLowerCase().includes('electric')"
          :singleTransaction="singleTransaction"
        />
        <WithdrawalReceipt
          v-else-if="
            singleTransaction.type.toLowerCase().includes('bank') ||
            singleTransaction.type.toLowerCase().includes('charge')
          "
          :singleTransaction="singleTransaction"
        />
        <WalletTransferReceipt
          v-else-if="singleTransaction.type.toLowerCase().includes('transfer')"
          :singleTransaction="singleTransaction"
        />
        <GeneralReceipt v-else :singleTransaction="singleTransaction" />
        <hr class="mt-2" />
        <div class="flex pt-3 justify-between items-center text-xs">
          <div><a href="https://legend.ng">legendpay.ng</a></div>
          <div class="flex items-center">
            <div>
              <a href="mailto:experience@legend.ng"> experience@legend.ng</a>
            </div>
            <div class="text-primary-color ml-3 px-2 py-1">
              <a href="tel:0700-MY-LEGEND"> 0700-MY-LEGEND </a>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </main>
</template>

<script>
import AirtimeReceipt from '@/components/receipts/Airtime.Receipt.vue'
import DirectPayment from '@/components/receipts/DirectPayment.Receipt.vue'
// import ProductPurchase from '@/components/receipts/ProductPurchase.Receipt.vue'
import ElectricityReceipt from '@/components/receipts/Electricity.Receipt.vue'
import WithdrawalReceipt from '@/components/receipts/Withdrawal.Receipt.vue'
import WalletTransferReceipt from '@/components/receipts/WalletTransfer.Receipt.vue'
import GeneralReceipt from '@/components/Receipt.vue'
import { XIcon, DownloadIcon } from 'vue-feather-icons'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Receipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
  },
  components: {
    XIcon,
    VueHtml2pdf,
    DownloadIcon,
    AirtimeReceipt,
    DirectPayment,
    // ProductPurchase,
    ElectricityReceipt,
    WithdrawalReceipt,
    WalletTransferReceipt,
    GeneralReceipt,
  },
  data() {
    return {
      downloading: false,
      isAirtime: false,
    }
  },
  methods: {
    generateReport() {
      if (this.downloading) {
        return
      }

      this.downloading = true

      this.$refs.html2Pdf.generatePdf()
      this.$emit('closeModal')
    },
  },
}
</script>
