<template>
  <section class="text-legend-gray capitalize py-6 mt-7 relative text-sm">
    <div class="flex items-center justify-between">
      <img
        :src="`${url}/${singleTransaction.merchant.image}`"
        :alt="singleTransaction.merchant.name"
        class="provider-logo w-12"
      />
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Date
        </div>
        <div class="text-xs">
          {{ singleTransaction.created_at | moment('DD-MM-YYYY') }}
        </div>
      </div>
    </div>
    <div class="my-5 w-full h-1 bg-primary-color bg-opacity-50">
      <div class="w-1/2 h-full bg-primary-color"></div>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>merchant name</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.merchant.name
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>address</span>
      <span class="font-semibold text-legend-black dark:text-border-color"
        >{{ singleTransaction.merchant.address }},
        {{ singleTransaction.merchant.location }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Customer name</span>
      <span class="font-semibold text-legend-black dark:text-border-color"
        >{{ user.first_name }} {{ user.last_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Order</span>
      <span
        class="font-semibold text-legend-black dark:text-border-color ml-12"
        >{{ singleTransaction.product }}</span
      >
    </div>

    <div
      class="
        my-8
        xl:mx-14
        lg:mx-14
        md:mx-14
        mx-8
        flex
        items-center
        justify-center
        bg-secondary-green bg-opacity-10
        text-secondary-green
        p-2
        rounded-lg
        font-bold
        text-lg
      "
    >
      <span class="mr-2">Price: </span>
      <span>₦{{ singleTransaction.amount | moneyFormat }}</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MerchantReceipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
}
</script>
