<template>
  <main>
    <div
      class="
        text-legend-gray
        capitalize
        dark:text-border-color
        py-6
        mt-7
        relative
        text-sm
      "
    >
      <div>
        <h1 class="text-legend-black dark:text-border-color text-xl font-bold">
          Transaction
        </h1>
        <p class="text-sm mt-2">{{ singleTransaction.type }}</p>
      </div>
      <div class="mt-5">
        <h2
          class="
            text-legend-black
            dark:text-border-color
            text-sm
            font-semibold
            uppercase
          "
        >
          Customer Information
        </h2>
        <div class="font-semibold capitalize">
          {{ singleTransaction.name }}
        </div>
        <div class="text-primary-color text-sm my-1">{{ user.username }}</div>
      </div>
      <div class="mt-5">
        <h1
          class="
            text-legend-black
            dark:text-border-color
            text-sm
            font-semibold
            uppercase
          "
        >
          Description
        </h1>
        <p>{{ singleTransaction.description }}</p>
      </div>
      <div class="mt-5">
        <h1
          class="
            text-legend-black
            dark:text-border-color
            text-sm
            font-semibold
            uppercase
          "
        >
          Time Stamp
        </h1>
        <p class="my-2">{{ singleTransaction.date }}</p>
      </div>
      <div class="mt-5">
        <div
          class="
            text-legend-black
            dark:text-border-color
            text-sm
            font-semibold
            uppercase
          "
        >
          status
        </div>
        <span
          :class="
            singleTransaction.status === 'Success'
              ? 'text-secondary-green'
              : 'text-primary-color'
          "
          class="font-semibold rounded-full inline-block my-2"
          >{{ singleTransaction.status }}</span
        >
      </div>
      <div class="flex items-start mt-5">
        <div class="mr-32">
          <div
            class="
              text-legend-black
              dark:text-border-color
              text-sm
              font-semibold
              uppercase
            "
          >
            Reference NUmber
          </div>
          <div class="my-2">{{ singleTransaction.reference }}</div>
        </div>
        <div>
          <div
            class="
              text-legend-black
              dark:text-border-color
              text-sm
              font-semibold
              uppercase
            "
          >
            Type
          </div>
          <div class="my-2">{{ singleTransaction.type }}</div>
        </div>
      </div>
      <div class="mt-5 mb-3">
        <h1
          class="
            text-legend-black
            dark:text-border-color
            text-sm
            font-semibold
            uppercase
          "
        >
          amount
        </h1>
        <p class="font-bold text-secondary-green text-2xl">
          ₦ {{ singleTransaction.amount | moneyFormat }}
        </p>
      </div>
    </div>
    <!-- Download receipt -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1045"
      filename="Transaction Receipt"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-content-width="400px"
      pdf-orientation="portrait"
      @hasStartedGeneration="hasStartedGeneration()"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="text-legend-gray px-8 pt-6">
        <div class="mt-5">
          <h1 class="text-legend-black text-xl font-bold">Transaction</h1>
          <p class="text-sm mt-2">{{ singleTransaction.type }}</p>
        </div>
        <div class="mt-5">
          <h2 class="text-legend-black text-sm font-semibold uppercase">
            Customer Information
          </h2>
          <div class="font-semibold capitalize">
            {{ singleTransaction.name }}
          </div>
          <div class="text-primary-color text-sm my-1">{{ user.username }}</div>
        </div>
        <div class="mt-5">
          <h1 class="text-legend-black text-sm font-semibold uppercase">
            Description
          </h1>
          <p class="font-smaller">{{ singleTransaction.description }}</p>
        </div>
        <div class="mt-5">
          <h1 class="text-legend-black text-sm font-semibold uppercase">
            Time Stamp
          </h1>
          <p class="my-2 font-smaller">{{ singleTransaction.date }}</p>
        </div>
        <div class="mt-5">
          <div class="text-legend-black text-sm font-semibold uppercase">
            status
          </div>
          <span
            :class="
              singleTransaction.status === 'Success'
                ? 'text-secondary-green'
                : 'text-primary-color'
            "
            class="font-semibold rounded-full inline-block my-2"
            >{{ singleTransaction.status }}</span
          >
        </div>
        <div class="flex items-start mt-5">
          <div class="mr-32">
            <div class="text-legend-black text-sm font-semibold uppercase">
              Reference NUmber
            </div>
            <div class="my-2 font-smaller">
              {{ singleTransaction.reference }}
            </div>
          </div>
          <div>
            <div class="text-legend-black text-sm font-semibold uppercase">
              Type
            </div>
            <div class="my-2 font-smaller">{{ singleTransaction.type }}</div>
          </div>
        </div>
        <div class="mb-3">
          <h1 class="text-legend-black text-sm font-semibold uppercase">
            amount
          </h1>
          <p class="my-2 font-bold text-secondary-green text-2xl">
            ₦ {{ singleTransaction.amount | moneyFormat }}
          </p>
        </div>
      </section>
    </vue-html2pdf>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Receipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
  },
  components: { VueHtml2pdf },
  data() {
    return {
      downloading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    generateReport() {
      if (this.downloading) {
        return
      }

      this.downloading = true

      this.$refs.html2Pdf.generatePdf()
      this.$emit('closeModal')
    },
  },
}
</script>
