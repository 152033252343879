<template>
  <section class="text-legend-gray py-6 mt-7 relative text-sm">
    <div class="flex items-center justify-between">
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Transaction ID
        </div>
        <div class="text-xs">{{ singleTransaction.reference }}</div>
      </div>
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Date
        </div>
        <div class="text-xs">{{ singleTransaction.date }}</div>
      </div>
    </div>
    <div class="my-5 w-full h-1 bg-primary-color bg-opacity-50">
      <div class="w-1/2 h-full bg-primary-color"></div>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Customer email</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        user.email
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Customer phone number</span>
      <span class="font-semibold text-legend-black dark:text-border-color"
        >{{ user.country_code }}{{ user.phone }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Token</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.token
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Customer name</span>
      <span
        class="
          font-semibold
          text-legend-black
          dark:text-border-color
          capitalize
        "
        >{{ user.first_name }} {{ user.last_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Meter number</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.meter_number
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span>Meter name</span>
      <span
        class="
          font-semibold
          text-legend-black
          dark:text-border-color
          capitalize
        "
        >{{ singleTransaction.meter_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-start
        justify-between
      "
    >
      <span class="flex-shrink-0">Meter address</span>
      <span
        class="font-semibold text-legend-black dark:text-border-color ml-20"
        >{{ singleTransaction.meter_address }}</span
      >
    </div>
    <div
      class="
        mb-5
        pb-2
        text-sm
        flex
        items-center
        justify-between
        border-b border-gray-300
      "
    >
      <span>Number of units</span>
      <span class="font-semibold text-legend-black dark:text-border-color"
        >{{ singleTransaction.number_of_units }} units</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-start
        justify-between
      "
    >
      <span class="flex-shrink-0">Transaction status</span>
      <span
        class="font-semibold text-legend-black dark:text-border-color ml-20"
        >{{ singleTransaction.status }}</span
      >
    </div>
    <div
      class="
        my-14
        xl:mx-14
        lg:mx-14
        md:mx-14
        mx-8
        flex
        items-center
        justify-center
        bg-secondary-green bg-opacity-10
        text-secondary-green
        p-2
        rounded-lg
        font-bold
        text-lg
      "
    >
      <span class="mr-2">Amount: </span>
      <span>₦{{ singleTransaction.amount | moneyFormat }}</span>
    </div>
    <img
      src="@/assets/images/aedc-logo.svg"
      alt=""
      class="provider-logo w-20 absolute -top-20 right-0"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ElectricityReceipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
    downloading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
}
</script>
