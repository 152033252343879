<template>
  <div
    class="
      text-legend-gray
      dark:text-border-color
      px-8
      h-full
      overflow-y-scroll
      overscroll-contain
      pb-16
    "
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-5 -mt-1 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Buy Airtime
    </h1>
    <div class="my-3 text-sm">Easy and fast airtime top up.</div>
    <div
      class="
        switch-container
        rounded-lg
        flex
        items-center
        p-1
        my-8
        font-semibold
        text-sm text-legend-gray
        dark:text-border-color
        text-center
      "
    >
      <div
        class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
        :class="{ 'bg-white text-primary-color': isSelf }"
        @click="toggleSelf"
      >
        For myself
      </div>
      <div
        class="w-1/2 cursor-pointer py-3 px-6 rounded-lg capitalize"
        :class="{ 'bg-white text-primary-color': isOthers }"
        @click="toggleOthers"
      >
        For others
      </div>
    </div>
    <div class="mt-6 mb-2 font-semibold">Select provider</div>
    <div class="mb-6">
      <div class="flex">
        <div
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="providerName = 'GLO'"
        >
          <img
            src="@/assets/images/globacom-logo.svg"
            alt="buy globacom airtime"
          />
          <img
            v-if="providerName === 'GLO'"
            src="@/assets/images/check-mark-circle.svg"
            alt="buy globacom airtime"
            class="absolute left-2 top-2"
          />
        </div>
        <div
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="providerName = 'MTN'"
        >
          <img src="@/assets/images/mtn-logo.svg" alt="buy mtn airtime" />
          <img
            v-if="providerName === 'MTN'"
            src="@/assets/images/check-mark-circle.svg"
            alt="buy mtn airtime"
            class="absolute left-2 top-2"
          />
        </div>
        <div
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="providerName = 'AIRTEL'"
        >
          <img src="@/assets/images/airtel-logo.svg" alt="buy airtel airtime" />
          <img
            v-if="providerName === 'AIRTEL'"
            src="@/assets/images/check-mark-circle.svg"
            alt="buy airtel airtime"
            class="absolute left-2 top-2"
          />
        </div>
        <div
          class="
            w-20
            h-20
            mr-3.5
            bg-white
            dark:bg-dark-mode-primary
            rounded
            shadow-md
            flex
            items-center
            justify-center
            cursor-pointer
            relative
          "
          @click="providerName = 'ETISALAT'"
        >
          <img
            src="@/assets/images/9mobile-logo.svg"
            alt="buy 9mobile airtime"
          />
          <img
            v-if="providerName === 'ETISALAT'"
            src="@/assets/images/check-mark-circle.svg"
            alt="buy 9mobile airtime"
            class="absolute left-2 top-2"
          />
        </div>
      </div>
      <ValidationMessage v-if="loading && !$v.providerName.required">
        <span slot="message">You must select a provider.</span>
      </ValidationMessage>
    </div>

    <div class="mb-6">
      <div class="text-legend-gray dark:text-border-color capitalize w-full">
        <label class="font-semibold text-sm mb-1">phone number</label>
        <input
          v-model.number="phoneNumber"
          type="text"
          placeHolder="e.g 8030000000"
          class="
            phone-number
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
      <ValidationMessage v-if="loading && !$v.phoneNumber.required">
        <span slot="message">Phone number is required.</span>
      </ValidationMessage>
    </div>

    <div class="mb-8">
      <div
        class="
          font-semibold
          text-sm
          mb-1
          capitalize
          text-legend-gray
          dark:text-border-color
        "
      >
        amount
      </div>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage v-if="loading && !$v.amount.required">
        <span slot="message">Enter an amount to recharge.</span>
      </ValidationMessage>
    </div>
    <div class="relative">
      <custom-input
        :inputData="{
          label: 'Transaction pin',
          fieldName: 'pin',
          placeHolder: 'Enter your transaction pin',
        }"
        attributeType="password"
        @input-value="setValue"
      >
      </custom-input>
      <ValidationMessage v-if="loading && !$v.pin.required" class="-mt-6">
        <span slot="message">Enter your transaction pin</span>
      </ValidationMessage>
      <div v-if="user && !user.has_pin" class="flex items-center">
        <span>Don't have transaction pin?</span>
        <router-link
          :to="{ name: 'Profile', params: { username: user.username } }"
          class="
            text-primary-color
            font-semibold
            cursor-pointer
            capitalize
            ml-2
          "
        >
          Click here
        </router-link>
      </div>
    </div>
    <custom-button :loading="submitting" @sendAction="buyAirtime">
      <span slot="button-text">Buy</span>
    </custom-button>
  </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'

export default {
  name: 'BuyAirtime',
  components: {
    XIcon,
    CustomInput,
  },
  mounted() {
    this.switchTheme()

    if (this.isSelf) {
      this.phoneNumber = this.user.phone
      const node = document.querySelector('.phone-number')
      node.setAttribute('readonly', true)
      node.classList.add('cursor-not-allowed')
    }
  },
  data() {
    return {
      isSelf: true,
      isOthers: false,
      providerName: null,
      phoneNumber: null,
      amount: null,
      pin: null,
      notification: null,
      loading: false,
      submitting: false,
      distractionFree: true,
    }
  },
  validations() {
    return {
      providerName: { required },
      phoneNumber: { required },
      amount: { required },
      pin: { required },
    }
  },
  computed: {
    ...mapGetters(['user', 'mode']),
  },
  watch: {
    mode() {
      this.switchTheme()
    },
  },
  methods: {
    switchTheme() {
      const node = document.querySelector('.switch-container')
      if (this.mode === 'dark') {
        node.classList.add('dark-mode-bg')
        node.classList.remove('light-mode-bg')
      } else {
        node.classList.add('light-mode-bg')
        node.classList.remove('dark-mode-bg')
      }
    },
    toggleSelf() {
      this.isSelf = true
      this.isOthers = false
      this.phoneNumber = this.user.phone
      const node = document.querySelector('.phone-number')
      node.setAttribute('readonly', true)
      node.classList.add('cursor-not-allowed')
    },
    toggleOthers() {
      this.isSelf = false
      this.isOthers = true
      this.phoneNumber = null
      const node = document.querySelector('.phone-number')
      node.removeAttribute('readonly')
      node.classList.remove('cursor-not-allowed')
    },
    setValue(value) {
      this[value.field] = value.value
    },
    async buyAirtime() {
      try {
        if (this.submitting) {
          return
        }
        this.loading = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.submitting = true

        const { status, data } = await this.axios.post(
          'account/utility/airtime',
          {
            amount: Number(this.amount),
            phone_number: this.phoneNumber,
            provider: this.providerName,
            pin: this.pin,
          }
        )
        if (status === 200) {
          this.$emit('utility-purchase', {
            type: 'success',
            message: data.message,
          })

          this.submitting = false
          this.loading = false
        }
      } catch (error) {
        this.submitting = false
        this.loading = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>

<style scoped>
.switch-container {
  width: 20rem;
}

.light-mode-bg {
  background-color: rgba(253, 79, 0, 0.1);
}

.dark-mode-bg {
  background-color: #1c1c1c;
}

@media only screen and (max-width: 560px) {
  .switch-container {
    width: 100%;
  }

  .switch-container > div {
    font-size: 0.7rem;
  }
}
</style>
