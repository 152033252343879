import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosInstance from './services/axios'
import Vuelidate from 'vuelidate'
import vueDebounce from 'vue-debounce'
import Flutterwave from  'flutterwave-vue-v3'
import VueCurrencyInput from 'vue-currency-input'
import IdleMode from 'idle-vue'
import { mapGetters } from 'vuex'
import './assets/css/tailwind.css'
import './assets/css/main.css'

Vue.use(require('vue-moment'))
Vue.use(vueDebounce, {
  lock: true,
  listenTo: 'input'
})
Vue.use(Vuelidate)
Vue.use(VueCurrencyInput)
Vue.use(Flutterwave, { publicKey: process.env.VUE_APP_FLUTTERWAVE_PUBLIC_KEY })

Vue.filter('moneyFormat', function numberWithCommas(value) {
  return value >0 ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
},)

// Global component registeration
Vue.component('AppBar', () => /* webpackChunkName: 'appbar' */import('@/components/AppBar.vue'))
Vue.component('CustomModal', () => /* webpackChunkName: 'modal' */import('@/components/CustomModal.vue'))
Vue.component('CustomButton', () => /* webpackChunkName: 'button' */import('@/components/CustomButton.vue'))
Vue.component('CustomInput', () => /* webpackChunkName: 'button' */import('@/components/CustomInput.vue'))
Vue.component('SideNav', () => /* webpackChunkName: 'sidenav' */import('@/components/SideNav.vue'))
Vue.component('NotificationToast', () => /* webpackChunkName: 'toast' */import('@/components/notificationToast/NotificationToast.vue'))
Vue.component('ValidationMessage', () => /* webpackChunkName: 'validation' */import('@/components/FormValidationMessage.vue'))
Vue.component('LoadingSpinner', () => /* webpackChunkName: 'loadingspinner' */import('@/components/UI/PageLoad.vue'))

// Idle checker
const eventsHub = new Vue()

// Check App.vue for logout implementation
Vue.use(IdleMode, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 60,
  startAtIdle: false
})

Vue.config.productionTip = false
Vue.prototype.axios = axiosInstance

new Vue({
  router,
  store,
  computed: {
    ...mapGetters(['mode']),
  },
  created() {
    this.toggleTheme()
    this.$store.commit('SET_FUND_WALLET', false)
  },
  watch:{
    mode(){
      this.toggleTheme()
    }
  },
  methods:{
    toggleTheme() {
      if (this.mode === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    },
  },
  render: (h) => h(App),
}).$mount('#app')