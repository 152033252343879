<template>
  <main class="flex bg-transparent relative xl:px-6 lg:px-6 md:px-5 px-3">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section class="w-full">
      <main>
        <h1 class="text-xl font-bold">Dashboard</h1>
        <section
          class="
            w-full
            flex
            xl:flex-row
            lg:flex-row
            md:flex-col
            flex-col
            xl:items-center
            lg:items-center
            md:items-start
            items-start
          "
        >
          <div class="xl:w-auto lg:w-auto md:w-full w-full">
            <dashboard-card
              class="bg-primary-color"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance </span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'">
              <img
                src="@/assets/images/reward-pack.svg"
                alt="view reward points"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
          <div
            v-if="currentPlan"
            class="
              flex
              items-center
              xl:ml-28
              lg:ml-20
              md:ml-0
              ml-0
              xl:mt-0
              lg:mt-0
              md:mt-16
              mt-16
            "
          >
            <radial-progress-bar
              :diameter="175"
              :completed-steps="completedSteps"
              :total-steps="totalSteps"
              :stroke-width="14"
              :innerStrokeWidth="14"
              innerStrokeColor="#F9F4F3"
              startColor="#FD4F00"
              stopColor="#FD4F00"
            >
              <div class="text-xl font-bold">
                {{ currentPlan.expiry_in_days }}
              </div>
              <div class="text-sm text-legend-gray dark:text-border-color">
                days left
              </div>
            </radial-progress-bar>

            <div
              class="
                xl:ml-10
                lg:ml-10
                md:ml-6
                ml-6
                text-legend-gray
                dark:text-border-color
              "
            >
              <div class="text-sm">{{ currentPlan.bundle }}</div>
              <div class="font-bold my-3">
                ₦{{ currentPlan.product_balance | moneyFormat }}
              </div>
              <div
                class="text-primary-color text-sm font-semibold cursor-pointer"
                @click="renewSubscription = true"
              >
                Renew
              </div>
            </div>
          </div>
        </section>
      </main>
      <section class="my-12">
        <h1 class="text-lg font-bold">Quick actions</h1>
        <main
          class="
            mt-6
            grid
            xl:grid-cols-6
            lg:grid-cols-4
            md:grid-cols-3
            grid-cols-2
            gap-4
          "
        >
          <quick-actions-card
            :card-data="{
              background: 'rgba(253, 79, 0, 0.1)',
              src: 'payment-card.svg',
              alt: 'visit legend zone',
              heading: 'Legend Zone',
              headingColor: '#FD4F00',
              content: 'Top up your Legend Zone service',
            }"
            @openModal="makeSubscription = true"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(77, 115, 191, 0.1)',
              src: 'mobile-phone.svg',
              alt: 'Buy Airtime',
              heading: 'Buy Airtime',
              headingColor: '#4267B2',
              content: 'Buy Airtime from your favourite network',
            }"
            @openModal="buyingAirtime = true"
          />

          <quick-actions-card
            :card-data="{
              background: 'rgba(172, 93, 93, 0.1)',
              src: 'buy-data.svg',
              alt: 'Buy Data',
              heading: 'Buy Data',
              headingColor: '#AC5D5D',
              content: ' Easily top up your mobile data.',
            }"
            @openModal="buyingData = true"
          />

          <quick-actions-card
            :card-data="{
              background: 'rgba(208, 169, 36, 0.1)',
              src: 'shop.svg',
              alt: 'Shop for Products',
              heading: 'Shop for Products',
              headingColor: '#BA9826',
              content: 'Get easy access to product and services.',
            }"
            @openModal="$router.push({ name: 'Purchases' })"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(131, 181, 96, 0.1)',
              src: 'tv.svg',
              alt: 'Tv Subscription',
              heading: 'TV Subscription',
              headingColor: '#83B560',
              content: 'Buy TV cards and Subscribe for services.',
            }"
            @openModal="buyingTvSubscription = true"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(0, 180, 84, 0.1)',
              src: 'transfer.svg',
              alt: 'Transfer Funds',
              heading: 'Transfer Funds',
              headingColor: '#00B454',
              content: 'Send funds from your wallet to another.',
            }"
            @openModal="transferringFunds = true"
          />
          <!-- Modal for fundwallet is in App.vue file line 57 -->
          <quick-actions-card
            :card-data="{
              background: 'rgba(152, 210, 225, 0.2)',
              src: 'wallet.svg',
              alt: 'Fund Wallet',
              heading: 'Fund Wallet',
              headingColor: '#5D9CAC',
              content: 'Top up your wallet for easy transaction.',
            }"
            @openModal="$store.commit('SET_FUND_WALLET', true)"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(161, 207, 129, 0.25)',
              src: 'electricity.svg',
              alt: 'Buy Electricity',
              heading: 'Buy Electricity',
              headingColor: '#779464',
              content: 'Easily buy electricity prepaid card.',
            }"
            @openModal="buyingElectricity = true"
          />

          <quick-actions-card
            v-if="user && user.can_withdraw.toLowerCase() !== 'no'"
            :card-data="{
              background: '#F3E6F4',
              src: 'withdraw-funds.svg',
              alt: 'Withdraw Funds',
              heading: 'Withdraw Funds',
              headingColor: '#A15DAC',
              content: 'Withdraw funds from your wallet to your bank.',
            }"
            @openModal="withdrawFunds = true"
          />
        </main>
      </section>
      <section class="my-12">
        <h1 class="text-lg font-bold">Recent Transactions</h1>
        <DataTable :transactions="recentTransactions" />
      </section>
    </section>
    <!-- Confirm confirm fund transfer -->
    <main
      v-if="confirmTransaction"
      class="
        w-screen
        h-screen
        bg-legend-black bg-opacity-30
        fixed
        top-0
        left-0
        flex
        items-center
        justify-center
        z-50
      "
    >
      <div
        class="
          xl:w-1/3
          lg:w-1/2
          md:w-3/4
          w-11/12
          py-12
          px-8
          bg-primary-background
          shadow-lg
          rounded-lg
          text-legend-gray
        "
      >
        <div>
          You are about to make a transfer of
          <span class="text-lg font-semibold"
            >N{{ beneficiary.amount | moneyFormat }}</span
          >
          to
          <span class="text-lg font-semibold capitalize">{{
            beneficiary.full_name
          }}</span>
        </div>
        <div class="my-8 text-xl text-primary-color font-semibold">
          Are You Sure?
        </div>
        <twin-button
          @leftAction="confirmTransaction = false"
          @rightAction="transferFund"
          :loading="submitting"
        >
          <span slot="left-button">No, Cancel</span>
          <span slot="right-button">Yes, Continue</span>
        </twin-button>
      </div>
    </main>
    <custom-modal v-if="transferringFunds">
      <TransferFunds
        slot="cart"
        @closeModal="transferringFunds = false"
        @confirmAction="confirmAction"
      />
    </custom-modal>
    <custom-modal v-if="buyingAirtime">
      <BuyAirtime
        slot="cart"
        @closeModal="buyingAirtime = false"
        @utility-purchase="displayNotification"
      />
    </custom-modal>
    <custom-modal v-if="buyingData">
      <BuyData
        slot="cart"
        @closeModal="buyingData = false"
        @utility-purchase="displayNotification"
      />
    </custom-modal>
    <custom-modal v-if="buyingTvSubscription">
      <BuyTvSubscription
        slot="cart"
        @closeModal="buyingTvSubscription = false"
        @utility-purchase="displayNotification"
      />
    </custom-modal>
    <custom-modal v-if="buyingElectricity">
      <BuyElectricity slot="cart" @closeModal="buyingElectricity = false" />
    </custom-modal>
    <custom-modal v-if="makeSubscription">
      <RenewSubscription
        slot="cart"
        @closeModal="makeSubscription = false"
        @renewalSuccess="renewalSuccess"
      />
    </custom-modal>
    <custom-modal v-if="renewSubscription">
      <RenewSubscription
        slot="cart"
        @closeModal="renewSubscription = false"
        @renewalSuccess="renewalSuccess"
      />
    </custom-modal>
    <custom-modal v-if="withdrawFunds">
      <WithdrawFunds
        slot="cart"
        @closeModal="withdrawFunds = false"
        @withdrawSuccess="withdrawSuccess"
        @response-message="responseMessage"
      />
    </custom-modal>
    <custom-modal v-if="transferSuccessful">
      <TransferSuccessful
        slot="cart"
        :successMessage="transferSuccessMessage"
        @closeModal="transferSuccessful = false"
      />
    </custom-modal>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import QuickActionsCard from '@/components/QuickActionsCard.vue'
import RadialProgressBar from 'vue-radial-progress'
import DataTable from '@/components/DataTable.vue'
import TransferFunds from '@/components/TransferFunds.vue'
import BuyAirtime from '@/components/BuyAirtime.vue'
import BuyData from '@/components/BuyData.vue'
import BuyTvSubscription from '@/components/BuyTvSubscription.vue'
import BuyElectricity from '@/components/BuyElectricity.vue'
import RenewSubscription from '@/components/RenewSubscription.vue'
import TransferSuccessful from '@/components/TansferSuccessful.vue'
import TwinButton from '@/components/TwinButton.vue'
import WithdrawFunds from '@/components/WithdrawFunds.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'UserDashboard',
  components: {
    RadialProgressBar,
    DashboardCard,
    QuickActionsCard,
    DataTable,
    TransferFunds,
    BuyAirtime,
    BuyData,
    BuyTvSubscription,
    BuyElectricity,
    RenewSubscription,
    TwinButton,
    TransferSuccessful,
    WithdrawFunds,
  },
  mounted() {
    this.getProfile()
    this.fetchRecentTransactions()
    this.fetchSubscription()
  },
  data() {
    return {
      recentTransactions: null,
      totalSteps: 30,
      transferringFunds: false,

      buyingAirtime: false,
      buyingElectricity: false,
      buyingData: false,
      makeSubscription: false,
      renewSubscription: false,
      confirmTransaction: false,
      transferSuccessful: false,
      showWalletBalance: false,
      showRewardPoints: false,
      buyingTvSubscription: false,
      withdrawFunds: false,
      submitting: false,
      notification: null,
      transferSuccessMessage: null,
      beneficiary: null,
    }
  },
  computed: {
    ...mapGetters([
      'walletBalance',
      'rewardPoints',
      'currentPlan',
      'user',
      'fundingWallet',
    ]),
    completedSteps() {
      let timeElapsed

      if (this.currentPlan) {
        timeElapsed = this.totalSteps - this.currentPlan.expiry_in_days
      }

      return timeElapsed
    },
  },
  methods: {
    saveTransaction(status) {
      status
        ? (this.savingTransaction = true)
        : (this.savingTransaction = false)
    },
    displayNotification(payload) {
      this.notification = {
        type: payload.type,
        message: payload.message,
      }
      this.fetchWalletBalance()
    },
    responseMessage(message) {
      this.buyingElectricity = false
      this.notification = {
        type: 'error',
        message,
      }
    },
    withdrawSuccess() {
      this.withdrawFunds = false
      this.notification = {
        type: 'success',
        message: 'Withdrawal successful.',
      }
      this.fetchWalletBalance()
    },
    renewalSuccess() {
      this.renewSubscription = false
      this.notification = {
        type: 'success',
        message: 'Subscription renewal successful.',
      }
      this.fetchWalletBalance()
    },
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    confirmAction(payload) {
      this.confirmTransaction = true
      this.beneficiary = payload
    },
    async transferFund() {
      try {
        if (this.submitting) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post(
          '/account/wallet/transfer',
          {
            amount: Number(this.beneficiary.amount),
            username: this.beneficiary.username,
            pin: this.beneficiary.transaction_pin,
          }
        )

        if (status === 200) {
          this.confirmTransaction = false
          this.transferringFunds = false
          this.submitting = false
          this.transferSuccessful = true
          this.transferSuccessMessage = data
          await this.fetchWalletBalance()
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.confirmTransaction = false
        this.transferringFunds = false
        this.submitting = false
      }
    },
    async fetchRecentTransactions() {
      try {
        const { status, data } = await this.axios.get(
          '/account/transactions?filter=recent'
        )

        if (status === 200) {
          this.recentTransactions = data.data.transactions
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchSubscription() {
      try {
        const { status, data } = await this.axios.get('/account/subscription')

        if (status === 200) {
          this.$store.commit('SET_CURRENT_PLAN', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchWalletBalance() {
      try {
        const { status, data } = await this.axios.get('/account/wallet')
        if (status === 200) {
          this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async getProfile() {
      try {
        const { status, data } = await this.axios.get('/account')

        if (status === 200) {
          this.$store.commit('SET_USER', data.data)
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        }
      }
    },
  },
}
</script>
