<template>
  <section class="px-10">
    <x-icon
      size="1.3x"
      class="custom-class mb-4 -ml-6 -mt-2 text-primary-color cursor-pointer"
      @click="$emit('closeModal')"
    ></x-icon>
    <img
      src="@/assets/images/pana.svg"
      alt="transfer successfull"
      class="block mx-auto my-16"
    />
    <h1 class="text-2xl font-bold text-center capitalize leading-loose">
      transfer successful
    </h1>
    <div
      class="text-legend-gray mb-16 text-center tracking-wider leading-relaxed"
    >
      {{ successMessage.message }} You can download the transaction receipt
      below.
    </div>
    <twin-button
      @leftAction="$emit('closeModal')"
      @rightAction="generateReport"
      :loading="downloading"
    >
      <span slot="left-button">Close</span>
      <span slot="right-button">Download Receipt</span>
    </twin-button>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1045"
      filename="receipt"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-content-width="400px"
      pdf-orientation="portrait"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="text-legend-gray dark:text-border-color px-8">
          <img
            src="@/assets/images/legend-pay-logo-dark.svg"
            alt="Legend pay logo"
          />
          <div
            class="
              text-legend-gray
              dark:text-border-color
              text-xs
              mt-4
              capitalize
            "
          >
            No 15, Bangui Street, Wuse 2, Abuja FCT.
          </div>
          <div class="mt-7">
            <h1
              class="text-legend-black dark:text-border-color text-xl font-bold"
            >
              Transaction
            </h1>
            <p class="text-sm mt-2">{{ successMessage.data.type }}</p>
          </div>
          <div class="mt-7">
            <h2
              class="
                text-legend-black
                dark:text-border-color
                text-sm
                font-semibold
                uppercase
              "
            >
              Customer Information
            </h2>

            <div class="font-semibold capitalize">
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class="text-primary-color text-sm my-2">
              {{ successMessage.data.username }}
            </div>
          </div>
          <div class="mt-7">
            <h1
              class="
                text-legend-black
                dark:text-border-color
                text-sm
                font-semibold
                uppercase
              "
            >
              Description
            </h1>
            <p class="font-smaller">{{ successMessage.data.description }}</p>
          </div>
          <div class="mt-7">
            <h1
              class="
                text-legend-black
                dark:text-border-color
                text-sm
                font-semibold
                uppercase
              "
            >
              Time Stamp
            </h1>
            <p class="my-2 font-smaller">{{ successMessage.data.date }}</p>
          </div>
          <div class="mt-7">
            <div
              class="
                text-legend-black
                dark:text-border-color
                text-sm
                font-semibold
                uppercase
              "
            >
              status
            </div>
            <span
              :class="
                successMessage.data.status.toLowerCase() === 'success'
                  ? 'text-secondary-green'
                  : 'text-primary-color'
              "
              class="font-semibold rounded-full inline-block my-2"
              >{{ successMessage.data.status }}</span
            >
          </div>
          <div class="flex items-start mt-7">
            <div class="mr-32">
              <div
                class="
                  text-legend-black
                  dark:text-border-color
                  text-sm
                  font-semibold
                  uppercase
                "
              >
                Reference NUmber
              </div>
              <div class="my-2 font-smaller">
                {{ successMessage.data.reference }}
              </div>
            </div>
            <div>
              <div
                class="
                  text-legend-black
                  dark:text-border-color
                  text-sm
                  font-semibold
                  uppercase
                "
              >
                Type
              </div>
              <div class="my-2 font-smaller">
                {{ successMessage.data.type }}
              </div>
            </div>
          </div>
          <div class="mt-7 mb-3">
            <h1
              class="
                text-legend-black
                dark:text-border-color
                text-sm
                font-semibold
                uppercase
              "
            >
              amount
            </h1>
            <p class="my-2 font-bold text-secondary-green text-2xl">
              ₦ {{ successMessage.data.amount }}
            </p>
          </div>
          <hr class="mt-2" />
          <div class="flex pt-3 justify-between items-center text-xs">
            <div><a href="https://legendpay.ng">legendpay.ng</a></div>
            <div class="flex items-center">
              <div>
                <a href="mailto:experience@legendpay.ng">
                  experience@legendpay.ng</a
                >
              </div>
              <div class="text-primary-color ml-3 px-2 py-1">
                <a href="tel:0700-MY-LEGEND"> 0700-MY-LEGEND </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </section>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import TwinButton from '@/components/TwinButton.vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'TransferSuccessful',
  props: {
    successMessage: {
      type: Object,
      default: null,
    },
  },
  components: { XIcon, TwinButton, VueHtml2pdf },
  data() {
    return {
      downloading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    generateReport() {
      if (this.downloading) {
        return
      }

      this.downloading = true

      this.$refs.html2Pdf.generatePdf()
      this.$emit('closeModal')
    },
  },
}
</script>
