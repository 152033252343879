<template>
  <section
    v-if="singleTransaction"
    class="text-legend-gray py-6 mt-7 relative text-sm"
  >
    <div class="flex items-center justify-between">
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Transaction ID
        </div>
        <div class="text-xs dark:text-border-color">
          {{ singleTransaction.reference }}
        </div>
      </div>
      <div>
        <div
          class="
            font-semibold
            text-legend-black
            dark:text-border-color
            text-xs
            mb-1
          "
        >
          Date
        </div>
        <div class="text-xs dark:text-border-color">
          {{ singleTransaction.date | moment('DD-MM-YYYY') }}
        </div>
      </div>
    </div>
    <div class="my-5 w-full h-1 bg-primary-color bg-opacity-50">
      <div class="w-1/2 h-full bg-primary-color"></div>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color">Recipient Bank name</span>
      <span
        class="
          font-semibold
          text-legend-black
          dark:text-border-color
          capitalize
        "
        >{{ singleTransaction.bank_transfer_receipt.recipient_bank_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color capitalize"
        >Recipient account name</span
      >
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.bank_transfer_receipt.recipient_account_name
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color">Recipient account number</span>
      <span class="font-semibold text-legend-black dark:text-border-color">{{
        singleTransaction.bank_transfer_receipt.recipient_account_number
      }}</span>
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color">Sender name</span>
      <span
        class="
          font-semibold
          text-legend-black
          dark:text-border-color
          capitalize
        "
        >{{ singleTransaction.bank_transfer_receipt.sender_name }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color">Amount transferred</span>
      <span class="font-semibold text-secondary-green text-lg"
        >₦{{
          singleTransaction.bank_transfer_receipt.amount_transfered
            | moneyFormat
        }}</span
      >
    </div>
    <div
      class="
        mb-5
        border-b border-gray-300
        pb-2
        text-sm
        flex
        items-center
        justify-between
      "
    >
      <span class="dark:text-border-color">Transaction status</span>
      <span class="font-semibold text-legend-black dark:text-border-color"
        >Completed</span
      >
    </div>
    <!-- <img
      src="@/assets/images/first_bank-logo.svg"
      alt=""
      class="provider-logo w-14 absolute -top-20 right-0"
    /> -->
  </section>
</template>

<script>
export default {
  name: 'BankWithdrawalReceipt',
  props: {
    singleTransaction: {
      type: Object,
      default: null,
    },
  },
}
</script>
