<template>
  <main
    class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20 relative"
  >
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Withdraw funds
    </h1>
    <div class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Withdraw funds from your legend pay wallet to your bank
    </div>
    <custom-input
      :inputData="{
        label: 'Account number',
        fieldName: 'account_number',
        placeHolder: 'Enter account number',
      }"
      @input-value="setValue"
    >
      <ValidationMessage
        v-if="loading && !$v.account_number.required"
        slot="validation"
      >
        <span slot="message">Account number is required.</span>
      </ValidationMessage>
      <ValidationMessage
        v-if="loading && !$v.account_number.numeric"
        slot="validation"
      >
        <span slot="message">Account number can only be digits.</span>
      </ValidationMessage>
    </custom-input>

    <div class="my-6 relative">
      <div class="w-full relative">
        <label
          class="
            text-legend-gray
            dark:text-border-color
            font-semibold
            block
            leading-relaxed
          "
          >Bank</label
        >
        <div
          class="
            bank-options
            flex
            items-center
            w-full
            border border-border-color
            rounded-lg
            py-3
            px-1
          "
          @click="getBanks"
        >
          <input
            v-model="bank_name"
            type="text"
            readonly
            class="
              w-full
              px-4
              bg-transparent
              focus:outline-none
              capitalize
              bank-options
            "
            @click="providerOptions = !providerOptions"
          />
        </div>
      </div>
      <img
        src="@/assets/images/down-chevron.svg"
        alt="select provider"
        class="w-3 absolute right-4 top-11 bank-options"
      />
      <ul
        v-click-outside="hideDropdown"
        v-if="providerOptions"
        class="
          absolute
          top-full
          w-full
          h-32
          overflow-y-scroll
          overscroll-y-contain
          bg-white
          border-gray-200
          dark:text-legend-black
          shadow-md
          text-sm
        "
      >
        <li v-if="providerOptions && !banks" class="font-semibold py-2 px-4">
          Loading...
        </li>
        <li
          v-for="bank in banks"
          :key="bank.id"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer capitalize"
          @click="selectBank(bank)"
        >
          {{ bank.name }}
        </li>
      </ul>

      <ValidationMessage
        v-if="loading && !$v.selectedBank.required"
        slot="validation"
      >
        <span slot="message">Please select a bank.</span>
      </ValidationMessage>
    </div>

    <div>
      <div
        class="
          font-semibold
          text-sm
          mb-1
          capitalize
          text-legend-gray
          dark:text-border-color
        "
      >
        amount
      </div>
      <currency-input
        type="text"
        placeholder="Enter amount"
        v-model.lazy="amount"
        currency="NGN"
        locale="en"
        class="
          border border-border-color
          rounded-lg
          w-full
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage
        v-if="loading && !$v.amount.required"
        slot="validation"
      >
        <span slot="message">Amount is required.</span>
      </ValidationMessage>
      <ValidationMessage v-if="loading && !$v.amount.numeric" slot="validation">
        <span slot="message">Amount can only be numbers.</span>
      </ValidationMessage>
    </div>

    <div class="my-6" v-if="confirmed">
      <label
        class="
          text-legend-gray
          dark:text-border-color
          font-semibold
          block
          leading-relaxed
        "
        >Recipient name</label
      ><input
        v-model="recipient_name"
        type="text"
        readonly
        placeholder="Recipient name"
        class="
          text-seconadry-border
          border border-border-color
          rounded-lg
          w-full
          p-3
          focus:outline-none
          bg-transparent
        "
      />
    </div>

    <custom-input
      v-if="confirmed"
      :inputData="{
        label: 'Transaction Pin',
        fieldName: 'transaction_pin',
        placeHolder: 'Enter transaction pin',
      }"
      attribute-type="password"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="custom-class pr-2 text-legend-gray eye-icon"
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage
        v-if="loading && !$v.transaction_pin.required"
        slot="validation"
      >
        <span slot="message">Pin is required.</span>
      </ValidationMessage>
    </custom-input>

    <custom-button
      :loading="submitting"
      @sendAction="confirmWithdraw"
      v-if="!confirmed"
    >
      <span slot="button-text">Confirm</span>
    </custom-button>

    <custom-button
      :loading="submitting"
      @sendAction="withdraw"
      v-if="confirmed"
    >
      <span slot="button-text">Withdraw</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, EyeIcon } from 'vue-feather-icons'
import { required, numeric } from 'vuelidate/lib/validators'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'WithdrawFunds',
  components: {
    XIcon,
    EyeIcon,
  },
  data() {
    return {
      account_number: null,
      amount: null,
      recipient_name: null,
      bank_name: null,
      transaction_pin: null,
      loading: false,
      banks: null,
      selectedBank: null,
      providerOptions: false,
      confirmed: false,
      submitting: false,
      notification: null,
    }
  },
  validations() {
    return {
      account_number: { required, numeric },
      selectedBank: { required },
      amount: { required, numeric },
      transaction_pin: { required },
    }
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('bank-options')) {
        this.providerOptions = false
      }
    },
    async getBanks() {
      try {
        const { status, data } = await this.axios.get(
          '/account/wallet/withdrawal/banks'
        )
        if (status === 200) {
          this.banks = data.data
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.providerOptions = false
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        }
      }
    },
    selectBank(bank) {
      this.bank_name = bank.name
      this.selectedBank = bank
      this.providerOptions = false
    },
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    async confirmWithdraw() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (
          this.$v.account_number.$error ||
          this.$v.selectedBank.$error ||
          this.$v.amount.$error
        ) {
          return
        }

        this.submitting = true
        const { status, data } = await this.axios.get(
          `/account/wallet/withdrawal?account_number=${this.account_number}&bank_code=${this.selectedBank.code}&amount=${this.amount}`
        )

        if (status === 200) {
          this.recipient_name = data.data.account_name
          this.confirmed = true
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
      this.submitting = false
    },
    async withdraw() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true
        const { status } = await this.axios.post('/account/wallet/withdrawal', {
          account_number: this.account_number,
          account_name: this.recipient_name,
          bank_code: this.selectedBank.code,
          bank_name: this.selectedBank.name,
          amount: this.amount,
          pin: this.transaction_pin,
        })

        if (status === 200) {
          this.$emit('withdrawSuccess')
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
        this.loading = false
      }
    },
    toggleVisibility() {
      const nodes = document.querySelectorAll('.eye-icon')
      if (this.attributeType === 'password') {
        this.attributeType = 'text'
        nodes.forEach((node) => {
          node.classList.remove('text-legend-gray')
          node.classList.add('text-primary-color')
        })
      } else {
        this.attributeType = 'password'
        nodes.forEach((node) => {
          node.classList.remove('text-primary-color')
          node.classList.add('text-legend-gray')
        })
      }
    },
  },
}
</script>
