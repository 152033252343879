<template>
  <main
    v-if="inputData"
    class="text-legend-gray dark:text-border-color mb-8 capitalize"
  >
    <div class="font-semibold text-sm mb-1">{{ inputData.label }}</div>
    <div class="border border-border-color rounded-lg flex items-center p-3">
      <div
        class="
          text-primary-color
          font-bold
          cursor-pointer
          uppercase
          flex-shrink-0
        "
      >
        <slot name="inline-text-left" />
      </div>
      <input
        v-model.trim="inputValue"
        :type="attributeType"
        :placeholder="inputData.placeHolder"
        class="w-full bg-transparent focus:outline-none"
      />
      <div
        class="
          text-primary-color
          font-bold
          cursor-pointer
          uppercase
          flex-shrink-0
        "
      >
        <slot name="inline-text-right" />
      </div>
    </div>
    <slot name="validation" />
  </main>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    inputData: {
      type: Object,
      default: () => {},
    },
    attributeType: {
      type: String,
      default: 'text',
    },
  },
  watch: {
    inputValue() {
      this.$emit('input-value', {
        value: this.inputValue,
        field: this.inputData.fieldName,
      })
    },
  },
  data() {
    return {
      inputValue: null,
    }
  },
}
</script>
