<template>
  <div
    class="quick-actions p-4 cursor-pointer"
    :style="`background-color: ${cardData.background}`"
    @click="$emit('openModal')"
  >
    <img :src="getImgUrl(cardData.src)" :alt="cardData.alt" class="mb-4" />
    <div class="font-semibold mb-2" :style="`color: ${cardData.headingColor}`">
      {{ cardData.heading }}
    </div>
    <div class="text-legend-gray dark:text-border-color text-sm">
      {{ cardData.content }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'QuickActionsCard',
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const nodes = document.querySelectorAll('.quick-actions')

    nodes.forEach((node) => {
      if (this.mode === 'dark') {
        node.classList.add('dark-bg')
      } else {
        node.classList.remove('dark-bg')
      }
    })
  },
  computed: {
    ...mapGetters(['mode']),
  },
  watch: {
    mode() {
      const nodes = document.querySelectorAll('.quick-actions')

      nodes.forEach((node) => {
        if (this.mode === 'dark') {
          node.classList.add('dark-bg')
        } else {
          node.classList.remove('dark-bg')
        }
      })
    },
  },
  methods: {
    getImgUrl(img) {
      return require(`@/assets/images/dashboard-cards/${img}`)
    },
  },
}
</script>

<style scoped>
.dark-bg {
  background-color: #1c1c1c !important;
}
</style>
