const state = {
  token: null,
  username: null,
  user: null,
  walletBalance: null,
  rewardPoints: null,
  currentPlan: null,
  currency: null,
  amount: 0,
  tx_ref: null,
  savingTransaction: false,
  fundingWallet: false,
  activeFamilyWallet: false,
  family: null,
}

const getters = {
  token: (state) => state.token,
  username: (state) => state.username,
  walletBalance: (state) => state.walletBalance,
  rewardPoints: (state) => state.rewardPoints,
  user: (state) => state.user,
  currentPlan: (state) => state.currentPlan,
  savingTransaction: (state) => state.savingTransaction,
  fundingWallet: (state) => state.fundingWallet,
}

const actions = {
  logOut({ commit }) {
    commit('RESET_TOKEN')
    commit('RESET_USERNAME')
    commit('RESET_WALLET_BALANCE')
    commit('RESET_USER')
    localStorage.removeItem('LP-customer')
  },
  setTransactionData({ commit }, { ref, currency, amount }) {
    commit('SET_TRANSACTION_REF', ref);
    commit('SET_CURRENCY', currency);
    commit('SET_AMOUNT', isNaN(amount) ? 0 : amount);
  }
}

const mutations = {
  SET_TOKEN: (state, token) => (state.token = token),
  SET_USERNAME: (state, username) => (state.username = username),
  SET_TRANSACTION_REF(state, ref) {
    state.tx_ref = ref
  },
  SET_CURRENCY(state, currency) {
    state.currency = currency
  },
  SET_AMOUNT(state, amount) {
    state.amount = amount
  },
  SET_WALLET_BALANCE: (state, walletBalance) =>
    (state.walletBalance = walletBalance),
  RESET_WALLET_BALANCE: (state) => (state.walletBalance = null),
  SET_REWARD_POINTS: (state, rewardPoints) =>
    (state.rewardPoints = rewardPoints),
  RESET_TOKEN: (state) => (state.token = null),
  RESET_USERNAME: (state) => (state.username = null),
  SET_USER: (state, user) => (state.user = user),
  RESET_USER: (state) => (state.user = null),
  SET_CURRENT_PLAN: (state, currentPlan) => (state.currentPlan = currentPlan),
  SET_TRANSACTION_STATUS: (state, status) => (state.savingTransaction = status),
  SET_FUND_WALLET: (state, value) => (state.fundingWallet = value),
  UPDATE_FAMILY: (state, user) => (state.user = user),
  UPDATE_FAMILY_SIZE: (state, size) => (state.user.family.size = size),
  UPDATE_KYC_STATUS: (state) => (state.user.is_kyc_verified = true),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
