<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain pb-20 px-8">
    <div class="sticky top-0 pb-4 z-10">
      <x-icon
        size="1.3x"
        class="custom-class block ml-auto text-primary-color cursor-pointer"
        @click="$emit('close-cart')"
      ></x-icon>
      <h1 class="font-bold text-xl capitalize">notifications</h1>
      <div class="my-4 text-legend-gray dark:text-border-color">
        Have a summary of all notifications
      </div>
    </div>
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="mt-5 flex"
    >
      <div class="w-full mb-3">
        <div>{{ notification.message }}</div>
        <div class="font-semibold text-secondary-border mt-2">
          {{ notification.created_at }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { XIcon } from 'vue-feather-icons'
export default {
  name: 'Notification',
  props: {
    notifications: {
      type: Array,
      default: null,
    },
  },
  components: {
    XIcon,
  },
}
</script>
